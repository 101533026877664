import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Button, Col, Input, Row} from 'reactstrap'
import {AgGridReact} from 'ag-grid-react'
//import { LicenseManager } from  'ag-grid-enterprise'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import moment from "moment"
import images from "../stores/images"

//LicenseManager.setLicenseKey("CompanyName=Vakt Global Limited,LicensedApplication=VAKT,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-008165,ExpiryDate=22_May_2021_[v2]_MTYyMTYzODAwMDAwMA==177710523d6e6207c1afc12ad79488d2")
//LicenseManager.setLicenseKey("FlexIT_2Devs_Expires_10-Jun-2018_MTUyODU4NTIwMDAwMA==89aad911f67c7b94f7a599b678485e45")

//https://www.ag-grid.com/react-data-grid/column-properties/
const containerStyle = {marginTop: '10px', borderRadius: '0.75rem', padding: 3, backgroundColor: '#FFFFFF'}

const Table = ({
                 rows,
                 cols,
                 buttons,
                 rowStyle,
                 tableName,
                 defaultColDef,
                 onRowDoubleClicked,
                 exportButton = true,
                 searchTable = true,
                 rowSelection = 'single',
                 checkboxSelection = false,
                 onSelectionChanged = null
               }) => {
  const [searchText, setSearchText] = useState('')
  const [dataSource, setDataSource] = useState(rows)

  const gridRef = useRef()

  useEffect(() => {
    setDataSource(rows)
  }, [rows])

  const searchTextRef = useRef(searchText)
  searchTextRef.current = searchText


  const exportCSV = () => {
    let now = moment().format('YYYY_MM_DD_HH_mm_ss')
    gridRef.current.api.exportDataAsCsv({fileName: `${tableName ? tableName.replace(/\s/g, '_') : 'export'}_${now}.csv`})
  }

  const onSearchChange = ({target: {value}}) => {
    const lowerSearchText = value && value.toLowerCase()
    const newData = rows.filter((p) => {
      return cols.reduce((acc, col) => {
        const v = (p[col.field] + '').toLowerCase()
        return acc || v.indexOf(lowerSearchText) !== -1
      }, false)
    })
    setSearchText(value)
    setDataSource(newData)
  }

  // DefaultColDef sets props common to all Columns
  const innerDefaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true

  }))

  // Example of consuming Grid Event
  const cellClickedListener = useCallback(event => {
    console.log('cellClicked', event)
  }, [])

  const onModelUpdated = ({columnApi}) => {
    /*
    const allColumnIds = [];
    columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    columnApi.autoSizeColumns(allColumnIds, true);
     */
  }

  return (
    <div>
      <Row>
        {searchTable ? <Col lg={3}>
          <label>
            Buscar:
            <Input
              size="sm"
              type="text"
              style={{padding: 5}}
              value={searchText}
              onChange={onSearchChange}
            />
          </label>
        </Col> : null}

        <Col className={' text-right'}>
          {
            buttons ?
              buttons.map(button =>
                <Button style={{marginTop: 20}} onClick={button.onClick} size={'sm'}>
                    <span className="btn-inner--icon">
                    <i className={`ni ${button.icon}`}/>
                  </span> {button.label}
                </Button>) : null
          }
          {
            exportButton ? <Button style={{marginTop: 20}} onClick={exportCSV} size={'sm'}>
            <span className="btn-inner--icon">
            <i className="ni ni-bag-17"/>
          </span> Exportar a Excel </Button> : null
          }

        </Col>
      </Row>
      <Row>
        <Col>
          <div style={containerStyle}>
            <div className="ag-theme-material" style={{width: '100%', height: 500}}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API

                rowData={dataSource} // Row Data for Rows

                columnDefs={cols} // Column Defs for Columns
                defaultColDef={defaultColDef || innerDefaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection={rowSelection}// Options - allows click selection of rows
                enableCellTextSelection={false}
                onSelectionChanged={onSelectionChanged}
                //onCellClicked={cellClickedListener} // Optional - registering for Grid Event
                onRowDoubleClicked={onRowDoubleClicked}
                checkboxSelection={checkboxSelection}
                onRowDataChanged={onModelUpdated}
                onGridReady={onModelUpdated}
                onModelUpdated={onModelUpdated}
                pagination={true}
                getRowStyle={rowStyle}
                localeText={AG_GRID_LOCALE_ES}
              />
            </div>
          </div>
        </Col>

      </Row>
    </div>
  )
}

export const Formatters = {
  cash: ({value}) => Object.cash(value),
  number: ({value}) => Object.formatNumber(value),
  datetime: ({value}) => Object.datetime(value),
  date: ({value}) => Object.date(value),
  time: ({value}) => Object.time(value),
  status: ({value}) => Object.status(value),
  image: ({value}) => <img src={images[value] || images.default} width={30} alt={value}/>,
  base64image: ({value}) => <img src={value || images.default} width={30} alt={"Logo"}/>,
  boolean: ({value}) => value ? 'SI' : 'NO'

}
// Example locale file for English, give this to your locale team to translate

export const AG_GRID_LOCALE_ES = {
  // Set Filter
  selectAll: '(Seleccionar todo)',
  selectAllSearchResults: '(Seleccionar todos los resultados)',
  searchOoo: 'Buscar...',
  blanks: '(En blanco)',
  noMatches: 'Sin coincidencias',

  // Number Filter & Text Filter
  filterOoo: 'Filtrar...',
  equals: 'Igual',
  notEqual: 'Diferente',
  blank: 'En blanco',
  notBlank: 'No en blanco',
  empty: 'Seleccione uno',

  // Number Filter
  lessThan: 'Menor que',
  greaterThan: 'Mayor que',
  lessThanOrEqual: 'menor o igual que',
  greaterThanOrEqual: 'mayor o igual que',
  inRange: 'En el rango',
  inRangeStart: 'desde',
  inRangeEnd: 'hasta',

  // Text Filter
  contains: 'Contiene',
  notContains: 'No contiene',
  startsWith: 'Empieza con',
  endsWith: 'Termina con',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'Y',
  orCondition: 'O',

  // Filter Buttons
  applyFilter: 'Aplicar',
  resetFilter: 'Reiniciar',
  clearFilter: 'Limpiar',
  cancelFilter: 'Cancelar',

  // Filter Titles
  textFilter: 'Filtro de texto',
  numberFilter: 'Filtro de número',
  dateFilter: 'Filtro de fecha',
  setFilter: 'Definir filtro',

  // Side Bar
  columns: 'Columnas',
  filters: 'Filtros',

  // columns tool panel
  pivotMode: 'Modo Pivot',
  groups: 'Grupo de filas',
  rowGroupColumnsEmptyMessage: 'Arrastre aqui para definir un grupo de filas',
  values: 'Valores',
  valueColumnsEmptyMessage: 'Arrastre aqui para agregar',
  pivots: 'Etiqueta de columna',
  pivotColumnsEmptyMessage: 'Arrastre aqui para definir la etiqueta de columna',

  // Header of the Default Group Column
  group: 'Grupo',

  // Row Drag
  rowDragRows: 'filas',

  // Other
  loadingOoo: 'Cargando...',
  noRowsToShow: 'No hay filas para mostrar',
  enabled: 'Habilitado',

  // Menu
  pinColumn: 'Pin Columna',
  pinLeft: 'Pin Izquierda',
  pinRight: 'Pin Derecha',
  noPin: 'No Pin',
  valueAggregation: 'Agregar valores',
  autosizeThiscolumn: 'Tamaño automatico esta columna',
  autosizeAllColumns: 'Tamaño automatico todas las columnas',
  groupBy: 'Agrupar por',
  ungroupBy: 'Desagrupar por',
  addToValues: 'agregar ${variable} a los valores',
  removeFromValues: 'eliminar ${variable} de los valores',
  addToLabels: 'agregar ${variable} a las etiquetas',
  removeFromLabels: 'eliminar ${variable} de las etiquetas',
  resetColumns: 'reiniciar columnas',
  expandAll: 'Expandir todo',
  collapseAll: 'Cerrar todo',
  copy: 'Copiar',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Copiar con encabezados',
  copyWithHeaderGroups: 'Copiar con encabezados del grupo',
  paste: 'Pegar',
  ctrlV: 'Ctrl+V',
  export: 'Exportar',
  csvExport: 'Exportar a CSV',
  excelExport: 'Exportar a Excel',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Sum',
  min: 'Min',
  max: 'Max',
  none: 'Ninguno',
  count: 'Contar',
  avg: 'Promedio',
  filteredRows: 'Filtrado',
  selectedRows: 'Seleccionado',
  totalRows: 'Total filas',
  totalAndFilteredRows: 'Filas',
  more: 'Mas',
  to: 'a',
  of: 'de',
  page: 'Pagina',
  nextPage: 'Página siguiente',
  lastPage: 'Ultima página',
  firstPage: 'Primera página',
  previousPage: 'Página anterior',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
  pivotChart: 'Pivot Chart',
  chartRange: 'Chart Range',

  columnChart: 'Column',
  groupedColumn: 'Grouped',
  stackedColumn: 'Stacked',
  normalizedColumn: '100% Stacked',

  barChart: 'Bar',
  groupedBar: 'Grouped',
  stackedBar: 'Stacked',
  normalizedBar: '100% Stacked',

  pieChart: 'Pie',
  pie: 'Pie',
  doughnut: 'Doughnut',

  line: 'Line',

  xyChart: 'X Y (Scatter)',
  scatter: 'Scatter',
  bubble: 'Bubble',

  areaChart: 'Area',
  area: 'Area',
  stackedArea: 'Stacked',
  normalizedArea: '100% Stacked',

  histogramChart: 'Histogram',

  combinationChart: 'Combination',
  columnLineCombo: 'Column & Line',
  AreaColumnCombo: 'Area & Column',

  // Charts
  pivotChartTitle: 'Pivot Chart',
  rangeChartTitle: 'Range Chart',
  settings: 'Configuración',
  data: 'Datos',
  format: 'Formato',
  categories: 'Categorias',
  defaultCategory: '(Ninguno)',
  series: 'Series',
  xyValues: 'X Y Values',
  paired: 'Paired Mode',
  axis: 'Axis',
  navigator: 'Navegador',
  color: 'Color',
  thickness: 'Thickness',
  xType: 'X Type',
  automatic: 'Automatico',
  category: 'Categoria',
  number: 'Numero',
  time: 'Hora',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Ticks',
  width: 'Width',
  height: 'Height',
  length: 'Length',
  padding: 'Padding',
  spacing: 'Spacing',
  chart: 'Chart',
  title: 'Titulo',
  titlePlaceholder: 'Chart title - double click to edit',
  background: 'Background',
  font: 'Fuente',
  top: 'Arriba',
  right: 'Derecha',
  bottom: 'Abajo',
  left: 'Izquierda',
  labels: 'Etiquetas',
  size: 'Tamaño',
  minSize: 'Minimum Size',
  maxSize: 'Maximum Size',
  legend: 'Legend',
  position: 'Position',
  markerSize: 'Marker Size',
  markerStroke: 'Marker Stroke',
  markerPadding: 'Marker Padding',
  itemSpacing: 'Item Spacing',
  itemPaddingX: 'Item Padding X',
  itemPaddingY: 'Item Padding Y',
  layoutHorizontalSpacing: 'Horizontal Spacing',
  layoutVerticalSpacing: 'Vertical Spacing',
  strokeWidth: 'Stroke Width',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Tooltips',
  callout: 'Callout',
  markers: 'Markers',
  shadow: 'Shadow',
  blur: 'Blur',
  xOffset: 'X Offset',
  yOffset: 'Y Offset',
  lineWidth: 'Line Width',
  normal: 'Normal',
  bold: 'Bold',
  italic: 'Italic',
  boldItalic: 'Bold Italic',
  predefined: 'Predefined',
  fillOpacity: 'Fill Opacity',
  strokeOpacity: 'Line Opacity',
  histogramBinCount: 'Bin count',
  columnGroup: 'Column',
  barGroup: 'Bar',
  pieGroup: 'Pie',
  lineGroup: 'Line',
  scatterGroup: 'X Y (Scatter)',
  areaGroup: 'Area',
  histogramGroup: 'Histogram',
  combinationGroup: 'Combination',
  groupedColumnTooltip: 'Grouped',
  stackedColumnTooltip: 'Stacked',
  normalizedColumnTooltip: '100% Stacked',
  groupedBarTooltip: 'Grouped',
  stackedBarTooltip: 'Stacked',
  normalizedBarTooltip: '100% Stacked',
  pieTooltip: 'Pie',
  doughnutTooltip: 'Doughnut',
  lineTooltip: 'Line',
  groupedAreaTooltip: 'Area',
  stackedAreaTooltip: 'Stacked',
  normalizedAreaTooltip: '100% Stacked',
  scatterTooltip: 'Scatter',
  bubbleTooltip: 'Bubble',
  histogramTooltip: 'Histogram',
  columnLineComboTooltip: 'Column & Line',
  areaColumnComboTooltip: 'Area & Column',
  customComboTooltip: 'Custom Combination',
  noDataToChart: 'No data available to be charted.',
  pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Linked to Grid',
  chartUnlinkToolbarTooltip: 'Unlinked from Grid',
  chartDownloadToolbarTooltip: 'Download Chart',
  seriesChartType: 'Series Chart Type',
  seriesType: 'Series Type',
  secondaryAxis: 'Secondary Axis',

  // ARIA
  ariaHidden: 'hidden',
  ariaVisible: 'visible',
  ariaChecked: 'checked',
  ariaUnchecked: 'unchecked',
  ariaIndeterminate: 'indeterminate',
  ariaDefaultListName: 'List',
  ariaColumnSelectAll: 'Toggle Select All Columns',
  ariaInputEditor: 'Input Editor',
  ariaDateFilterInput: 'Date Filter Input',
  ariaFilterList: 'Filter List',
  ariaFilterInput: 'Filter Input',
  ariaFilterColumnsInput: 'Filter Columns Input',
  ariaFilterValue: 'Filter Value',
  ariaFilterFromValue: 'Filter from value',
  ariaFilterToValue: 'Filter to value',
  ariaFilteringOperator: 'Filtering Operator',
  ariaColumn: 'Column',
  ariaColumnList: 'Column List',
  ariaColumnGroup: 'Column Group',
  ariaRowSelect: 'Press SPACE to select this row',
  ariaRowDeselect: 'Press SPACE to deselect this row',
  ariaRowToggleSelection: 'Press Space to toggle row selection',
  ariaRowSelectAll: 'Press Space to toggle all rows selection',
  ariaToggleVisibility: 'Press SPACE to toggle visibility',
  ariaSearch: 'Search',
  ariaSearchFilterValues: 'Search filter values',

  ariaRowGroupDropZonePanelLabel: 'Row Groups',
  ariaValuesDropZonePanelLabel: 'Values',
  ariaPivotDropZonePanelLabel: 'Column Labels',
  ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
  ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Column Menu',
  ariaLabelCellEditor: 'Cell Editor',
  ariaLabelDialog: 'Dialog',
  ariaLabelSelectField: 'Select Field',
  ariaLabelTooltip: 'Tooltip',
  ariaLabelContextMenu: 'Context Menu',
  ariaLabelSubMenu: 'SubMenu',
  ariaLabelAggregationFunction: 'Aggregation Function',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ',',
  decimalSeparator: '.'

}

export default Table
