import React, {useEffect, useState} from "react"
import {Button, Col, FormGroup, Input, Modal, Row} from "reactstrap"
import PackagesAssocModal from "./PackagesAssocModal"

const EditModal = ({data, toggle, visible, onSave}) => {
  const [id, setId] = useState()
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [payload, setPayload] = useState()
  const [providerName, setProviderName] = useState()
  const [assocVisible, setAssocVisible] = useState(false)

  useEffect(() => {
    setId(data.id)
    setUsername(data.username)
    setPassword(data.password)
    setPayload(data.payload)
    setProviderName(data.providerName)
  }, [data])


  const toggleAssoc = () => {
    setAssocVisible(!assocVisible)
  }

  return <div>
    <PackagesAssocModal toggle={toggleAssoc} visible={assocVisible} provider={data}/>
    <Modal
      className="modal-dialog-centered"
      isOpen={visible}
      toggle={() => toggle()}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="userModal">
          Proveedor {providerName}
        </h5>
        <button
          aria-label="Cerrar"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <label> Nombre de usuario </label>
              <Input
                className="form-control-alternative"
                defaultValue={data.username}
                placeholder="usuario"
                onChange={e => setUsername(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label> Contraseña </label>
              <Input
                className="form-control-alternative"
                placeholder="contraseña"
                defaultValue={data.password}
                type="text"
                onChange={e => setPassword(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label> Datos adicionales </label>
              <Input
                defaultValue={data.payload}
                className="form-control-alternative"
                placeholder="payload"
                onChange={e => setPayload(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label> Proveedor </label>
              <Input
                defaultValue={data.providerName}
                className="form-control-alternative"
                type="select"
                onChange={e => setProviderName(e.target.value)}
              >
                <option value="recargamos">Recargamos</option>
                <option value="refacil">Refacil</option>
                <option value="claro393">Plataforma 393</option>
                <option value="netflixProv">Plataforma Netflix</option>
                <option value="bemovil" disabled>BeMovil</option>
                <option value="tigogestion" disabled>Tigo Gestión</option>
                <option value="atienda" disabled>Atienda Claro</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="success" type="button" size={'sm'} onClick={toggleAssoc}>
          Asociar paquetes
        </Button>
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          size={'sm'}
          onClick={() => toggle()}
        >
          Cancelar
        </Button>
        <Button color="primary" type="button" size={'sm'} onClick={() => onSave({
          id,
          username,
          password,
          payload,
          providerName
        })}>
          Guardar
        </Button>
      </div>
    </Modal>
  </div>
}
export default EditModal
