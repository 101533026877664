import React, {useEffect, useRef, useState} from "react"
import CustomerNavbar from "../../../../components/Navbars/CustomerNavbar"
import Shape from "../../../../components/Shape"
import {Button, Col, Container, Row} from "reactstrap"
import SimpleFooter from "../../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from '../../../../components/Table'
import {confirm, toast} from '../../../../components/Alert'
import {useAppDataStore} from "../../../../stores/appDataStore"
import EditModal from "./EditModal"

import {Buffer} from "buffer";
import images from "../../../../stores/images";
import {useNavigate} from "react-router";

const Packages = observer(() => {

  const [selectedData, setSelectedData] = useState({})
  const [selectedRows, setSelectedRows] = useState([])
  const [productModalVisible, setProductModalVisible] = useState(false)
  const [data, setData] = useState([])
  const appDataStore = useAppDataStore()
  const navigate = useNavigate()
  const mainRef = useRef()

  useEffect(() => {
    refreshData()
  }, [])

  const columns = [
    {
      checkboxSelection: true,
      maxWidth: 50,
      minWidth: 50,
      headerName: '',
    },
    {
      field: 'position',
      headerName: '#',
      maxWidth: 60,
      minWidth: 60,
      flex: 1,
      cellRenderer: Formatters.number
    },
    {
      field: 'id',
      minWidth: 90,
      maxWidth: 90,
      headerName: 'ID',

    },
    {
      field: 'amount',
      minWidth: 110,
      flex: 1,
      headerName: 'Valor',
      cellRenderer: Formatters.cash

    },
    {
      field: 'name',
      flex: 1,
      headerName: 'Descripción',
      minWidth: 300,
    },
    {
      field: 'carrier',
      filter: true,
      minWidth: 140,
      maxWidth: 180,
      headerName: 'Proveedor',
    },
    {
      field: 'PackageCategory.category',
      filter: true,
      minWidth: 110,
      maxWidth: 150,
      headerName: 'Categoría',
    },
    {
      field: 'Product.name',
      filter: true,
      minWidth: 170,
      maxWidth: 200,
      headerName: 'Producto',
    },
    {
      field: 'enabled',
      headerName: 'Habilitado',
      maxWidth: 100,
      minWidth: 80,
      flex: 1,
      cellRenderer: Formatters.boolean
    },
    {
      field: 'createdAt',
      headerName: 'Fecha creación',
      valueFormatter: Formatters.datetime
    },
    {
      field: '',
      maxWidth: 130,
      minWidth: 120,
      headerName: 'Opciones',
      cellRenderer: ({data}) => {
        return <div className="action-icons">
          <Button size={'sm'}
                  onClick={() => {
                    setSelectedData(data)
                    toggleProductModal()
                  }}>
            <i className="fa fa-pencil p-0"/>
          </Button>
          <Button size={"sm"} onClick={async () => {
            let confirmRes = await confirm(`se ${!data.enabled ? 'activará' : 'desactivará'} el paquete ${data.name}`)
            if (confirmRes && confirmRes.value) {
              await appDataStore.post('/backoffice/packages/edit', {
                id: data.id,
                enabled: !data.enabled
              })
              await refreshData()
            }
          }}><i className={`p-0 ni ni-button-power ${!data.enabled ? 'text-success' : 'text-danger'}`}/>
          </Button>
        </div>
      }
    }
  ]

  const buttons = [{
    label: 'Crear Paquete',
    icon: 'ni-circle-08',
    onClick: () => {
      setSelectedData(null)
      toggleProductModal()
    }
  }, {
    label: 'Categorias',
    icon: 'ni-circle-08',
    onClick: () => {
      navigate('/customer/administration/packagesCategories')
    }
  },
    {
      label: 'Desactivar',
      icon: 'ni-circle-08',
      onClick: async () => {
        let modalRes = await confirm(`se desactivaran <b>${selectedRows.length}</b> paquetes. desea continuar?`)
        if (modalRes) {
          const id = selectedRows.reduce((previusValue, currentValue) => {
            previusValue.push(currentValue.id)
            return previusValue
          }, [])
          let res = await appDataStore.post('/backoffice/packages/disable', {id})
          if (res.result === 'error') return await toast(res.error)
          await refreshData()
        }
      }
    }
  ]

  const rowStyle = ({data}) => {
    let style = {}
    if (!data.enabled) style = {color: 'rgb(222,216,216)', fontStyle: 'italic'}
    return style
  }

  const refreshData = async () => {
    try {
      let {packages} = await appDataStore.get('/backoffice/packages')
      setData(packages)
    } catch (err) {
      console.log(err)
    }
  }

  const toggleProductModal = () => {
    setProductModalVisible(!productModalVisible)
  }
  const onSelectionChanged = ({api}) => {
    const selectedRows = api.getSelectedRows()
    setSelectedRows(selectedRows)
  }

  return (
    <>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <Row>
              <Col lg={12} md={12}>
                <EditModal
                  data={selectedData}
                  visible={productModalVisible}
                  toggle={toggleProductModal}
                  onSave={refreshData}
                />
                <Table
                  onRowDoubleClicked={({data}) => {
                    setSelectedData(data)
                    toggleProductModal()
                  }}
                  tableName={'Paquetes'}
                  cols={columns}
                  rows={data}
                  buttons={buttons}
                  rowStyle={rowStyle}
                  rowSelection={'multiple'}
                  onSelectionChanged={onSelectionChanged}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </>
  )
})

export default Packages
