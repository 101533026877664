import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {useAppDataStore} from "../../../../stores/appDataStore";
import Table, {Formatters} from "../../../../components/Table";
import moment from "moment";

const CreateSupportModal = ({toggle, visible, onSave}) => {

  const appDataStore = useAppDataStore()
  const [data, setData] = useState([])
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [text, setText] = useState()

  const columns = [
    {
      field: 'id',
      maxWidth: 70,
      headerName: 'Id',
    },
    {
      field: 'description',
      headerName: 'Producto',
      flex: 1,
    },
    {
      field: 'providerResponse',
      headerName: 'datos de venta',
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Valor',
      maxWidth: 100,
      valueFormatter: Formatters.cash
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      valueFormatter: Formatters.datetime
    }
  ]

  useEffect(() => {

    if (visible){
      setSelectedTransaction()
      fetchData()
    }
  }, [visible])

  const fetchData = async () => {
    const startDate = moment().subtract(30, 'days')
    const endDate = moment()
    const {transactions} = await appDataStore.getSalesReport(startDate, endDate, 'success')
    setData(transactions)
  }

  const onSelectionChanged = async (event) => {
    const {api} = event
    const selectedRows = api.getSelectedRows()
    const [transaction] = selectedRows
    setSelectedTransaction(transaction)
  }

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="xl"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Crear caso de soporte
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={12}>
          <FormGroup>
            <label> Descripción del problema</label>
            <Input
              className="form-control-alternative"
              placeholder=""
              type={'textarea'}
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg={12}>
          <Table searchTable={true} exportButton={false} cols={columns} rows={data}
                 onSelectionChanged={onSelectionChanged}
          />
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={() => toggle()}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={() => onSave(text, selectedTransaction)}>
        Aceptar
      </Button>
    </div>
  </Modal>
}
export default CreateSupportModal