import moment from 'moment'

Object.formatNumber = (nStr) => {
  if (nStr == null || nStr === '') {
    return 'no registra'
  }
  if (Object.isFloat(nStr)) {
    nStr = parseFloat(nStr).toFixed(2).toString()
  }
  nStr += ''
  var x = nStr.split('.')
  var x1 = x[0]
  var x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1,$2`)
  }
  return x1 + x2
}

Object.cash = function cash(val, currency) {
  if (val == null || val === '') {
    return 'no registra'
  }
  if (typeof currency === 'undefined') {
    currency = ''
  }
  if (!isNaN(val)) {
    return (currency === 'EUR' ? '€' : '$') + Object.formatNumber(val) + ' ' + currency
  } else {
    return val
  }
}

Object.status = function status(status) {
  status = status.toLowerCase()
  return status === 'success' ? 'Exitoso' : status === 'error' ? 'Error' : status === 'pending' ? 'pendiente' : status === 'rejected' ? 'Rechazado' : 'Desconocido'
}

Object.datetime = function date(date) {
  if (!date) return 'Sin datos'
  return moment(date).format("YYYY-MM-DD hh:mm:ss")
}

Object.date = function date(date) {
  if (!date) return 'Sin datos'
  return moment(date).format("YYYY-MM-DD")
}

Object.time = function date(date) {
  return moment(date).format("hh:mm:ss")
}

Object.isInt = function isInt(n) {
  return Number(n) === n && n % 1 === 0
}

Object.isFloat = function isFloat(n) {
  return Number(n) === n && n % 1 !== 0
}

Number.random = function (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// eslint-disable-next-line
Number.prototype.cash = function () {
  return Object.cash(this)
}

// eslint-disable-next-line
String.prototype.validateCarrier = function () {
  return this.length >= 3
}

// eslint-disable-next-line
String.prototype.validateMin = function () {
  return /^3\d{9}$/.test(this.trim())
}

String.prototype.status = function () {
  let status = {success: 'Exitosa', error: 'Error'}
  return status[this]
}

// eslint-disable-next-line
Number.prototype.validateAmount = String.prototype.validateAmount = function (min = 1000, max = 50000) {
  let value = this.valueOf()
  return value >= min && value <= max
}
