import React, {useEffect, useRef, useState} from "react"
import CustomerNavbar from "../../../../components/Navbars/CustomerNavbar"
import Shape from "../../../../components/Shape"
import {Button, Col, Container, Row} from "reactstrap"
import SimpleFooter from "../../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from '../../../../components/Table'
import {useAppDataStore} from "../../../../stores/appDataStore";
import CreateSupportModal from "./CreateSupportModal";
import ChatSupportModal from "./ChatSupportModal";
import {alert} from "../../../../components/Alert";

const Index = observer(({}) => {

  const [data, setData] = useState([])
  const [createSupportModalVisible, setCreateSupportModalVisible] = useState(false)
  const [selectedSupport, setSelectedSupport] = useState(null)

  const appDataStore = useAppDataStore()

  useEffect(() => {
    refreshData().then()
  }, [])

  const columns = [
    {
      field: 'id',
      maxWidth: 60,
      headerName: 'ID',
    },
    {
      field: 'Transaction.description',
      flex: 1,
      headerName: 'Producto'
    },
    {
      field: 'Transaction.amount',
      headerName: 'Valor',
      flex: 1,
      cellRenderer: Formatters.cash
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: 1,
      cellRenderer: Formatters.status
    },
    {
      field: 'text',
      headerName: 'Detalle soporte',
      flex: 1,
    },
    {
      field: 'lastResponse',
      headerName: 'Última respuesta',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha',
      flex: 1,
      cellRenderer: Formatters.datetime
    },
    {
      field: '',
      flex: 1,
      maxWidth: 150,
      headerName: 'Responder',
      cellRenderer: ({data}) => {
        return <div className="action-icons">
          <Button size={"sm"} onClick={async () => {
            setSelectedSupport(data)
          }}><i className={`p-0 ni ni-support-16`}/></Button>
        </div>
      }
    }
  ]


  const buttons = [{
    label: 'Crear caso soporte',
    icon: 'ni-support-16',
    onClick: () => {
      setCreateSupportModalVisible(true)
    }
  }]
  const refreshData = async () => {
    try {
      let {support} = await appDataStore.getSupports()
      setData(support)
    } catch (err) {
      console.log(err)
    }
  }

  const toggleCreateModal = () => {
    setCreateSupportModalVisible(!createSupportModalVisible)
  }

  const toggleUpdateModal = () => {
    if (selectedSupport) return setSelectedSupport(null)
  }
  const onSave = async (text, transaction) => {
    console.log(text, transaction)
    const res = await appDataStore.createSupport(text, transaction.id)
    if (res.result === 'error') return alert(res.error)
    await refreshData()
    toggleCreateModal()
  }

  const onUpdateSupport = async (text, supportId, attachment) => {
    const res = await appDataStore.updateSupport(text, supportId, attachment)
    if (res.result === 'error') return alert(res.error)
    await refreshData()
    toggleUpdateModal()
  }
  return (
    <div>
      <CustomerNavbar/>
      <main>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <Row>
              <Col lg={12} md={12}>
                {selectedSupport ?
                  <ChatSupportModal
                    support={selectedSupport}
                    toggle={toggleUpdateModal}
                    visible={selectedSupport}
                    onSave={onUpdateSupport}
                  /> : null}
                <CreateSupportModal
                  visible={createSupportModalVisible}
                  toggle={toggleCreateModal}
                  onSave={onSave}/>
                <Table
                  tableName={'Sesiones'}
                  cols={columns}
                  rows={data}
                  buttons={buttons}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Index
