import React from "react"
// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap"

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <hr/>
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href="https://netcarga.com.co"
                  >Netcarga</a>.
                </div>
              </Col>
              <Col md="6">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <NavLink
                      href="https://netcarga.com.co/about"
                      target="_blank"
                    >
                      Acerca de
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://netcarga.com.co/privacy"
                      target="_blank"
                    >
                      Politica de privacidad
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://netcarga.com.co/soporte"
                      target="_blank"
                    >
                      Soporte
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )
  }
}

export default SimpleFooter
