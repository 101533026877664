import React, {useEffect, useRef, useState} from "react"
import CustomerNavbar from "../../../../components/Navbars/CustomerNavbar"
import Shape from "../../../../components/Shape"
import {Button, Col, Container, Row,} from "reactstrap"
import SimpleFooter from "../../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from "../../../../components/Table";
import {alert, confirm, toast} from '../../../../components/Alert'
import {useAppDataStore} from "../../../../stores/appDataStore"
import UserModal from "./UserModal";
import BalanceModal from "./BalanceModal";
import ProductAssocModal from "./ProductAssocModal";

const Index = observer(() => {

  const [selectedUser, setSelectedUser] = useState(null)
  const [data, setData] = useState([])
  const [userModalVisible, setUserModalVisible] = useState(false)
  const [balanceModalVisible, setBalanceModalVisible] = useState(false)
  const [productAssocModalVisible, setProductAssocModalVisible] = useState(false)
  const appDataStore = useAppDataStore()

  const mainRef = useRef()

  const columns = [
    {
      field: 'id',
      maxWidth: 70,
      flex: 1,
      headerName: 'Id',
    },
    {
      filter: 'agTextColumnFilter',
      flex: 1,
      minWidth: 120,
      field: 'username',
      headerName: 'Usuario'
    },
    {
      field: 'name',
      flex: 1,
      minWidth: 140,
      headerName: 'Nombre',
    },
    {
      field: 'rol',
      maxWidth: 130,
      minWidth: 90,
      headerName: 'Perfil',
    },
    {
      field: 'balance',
      headerName: 'Saldo principal',
      minWidth: 120,
      maxWidth: 150,
      flex: 1,
      valueFormatter: Formatters.cash
    },
    {
      field: 'balance1',
      headerName: 'Saldo comisión',
      flex: 1,
      minWidth: 130,
      valueFormatter: Formatters.cash
    },
    {
      field: 'createdAt',
      headerName: 'Fecha cración',
      minWidth: 140,
      flex: 1,
      valueFormatter: Formatters.datetime
    },
    {
      field: 'options',
      minWidth: 215,
      headerName: 'Opciones',
      cellRenderer: ({data}) => {
        return data ? <div className="action-icons">
          <Button size={"sm"} onClick={() => {
            setSelectedUser(data)
            toggleProductAssocModal()
          }}><i className="p-0 ni ni-cart"/></Button>
          <Button size={"sm"} onClick={() => {
            setSelectedUser(data)
            toggleModalBalance()
          }}><i className="p-0 ni ni-money-coins"/></Button>
          <Button size={"sm"} onClick={() => {
            setSelectedUser(data)
            toggleModal()
          }}><i className="p-0 ni ni-settings-gear-65"/></Button>
          <Button size={"sm"} onClick={async () => {
            let res = await confirm('Se restablecerá la contraseña del cliente y se enviará a su número celular')
            if (res.value) {
              let res = await appDataStore.resetPassword(data.id)
              await refreshChilds()
              if (res.password) alert(`se ha creado una contraseña temporal para el usuario ${data.username}: ${res.password}`)
            }
          }}><i className="p-0 ni ni-lock-circle-open"/></Button>
          <Button size={"sm"} onClick={async () => {
            let res = await confirm(`se ${data.blocked ? 'activará' : 'desactivará'} el acceso a la plataforma del usuario ${data.username}`)
            if (res.value) {
              await appDataStore.updateAuthStatus(data.id, !data.blocked)
              await refreshChilds()
            }
          }}><i className={`p-0 ni ni-button-power ${data.blocked ? 'text-success' : 'text-danger'}`}/></Button>
        </div> : null
      }
    }
  ]

  const buttons = [{
    label: 'Crear usuario',
    icon: 'ni-circle-08',
    onClick: () => {
      setSelectedUser({})
      toggleModal()
    }
  }]

  const rowStyle = ({data}) => {
    let style = {}
    if (data.blocked) style = {color: 'rgb(222,216,216)', fontStyle: 'italic'}
    return style
  }

  useEffect(() => {
    refreshChilds()
  }, [])

  const refreshChilds = async () => {
    try {
      let childs = await appDataStore.updateChilds()
      setData(childs)
    } catch (err) {
      console.log(err)
    }
  }

  const toggleModal = () => {
    setUserModalVisible(!userModalVisible)
  }

  const toggleModalBalance = () => {
    setBalanceModalVisible(!balanceModalVisible)
  }

  const toggleProductAssocModal = () => {
    setProductAssocModalVisible(!productAssocModalVisible)
  }

  const saveBalance = async ({user, amount, action, balanceType, notes}) => {
    let res = await appDataStore.transferBalance(
      user.id,
      amount,
      action === 'reverse',
      balanceType,
      notes
    )
    if (res.error) return toast(res.error)
    await refreshChilds()
    await appDataStore.updateCustomer()
    toggleModalBalance()
  }

  const saveUser = async (user) => {
    let res
    debugger
    if (user.id) {
      res = await appDataStore.editChild(
        user.id,
        user.username,
        user.name,
        user.phone,
        user.email,
        user.docType,
        user.docNumber
      )
    } else {
      res = await appDataStore.createChild(
        user.username,
        user.password,
        user.rol,
        user.name,
        user.phone,
        user.email,
        user.docType,
        user.docNumber
      )
    }

    if (res.error) return toast(res.error)
    await refreshChilds()
    toggleModal()
  }

  const saveProductAssoc = async (user, products) => {

  }
  return (
    <div>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <Row>
              <Col lg={12} md={12}>
                {selectedUser ?
                  (<>
                    <BalanceModal
                      visible={balanceModalVisible}
                      toggle={toggleModalBalance}
                      user={selectedUser || {}}
                      onSave={saveBalance}
                    />
                    <UserModal
                      visible={userModalVisible}
                      toggle={toggleModal}
                      user={selectedUser || {}}
                      onSave={saveUser}
                    />
                    <ProductAssocModal
                      visible={productAssocModalVisible}
                      toggle={toggleProductAssocModal}
                      user={selectedUser || {}}
                      onSave={saveProductAssoc}
                    />
                  </>) : null}
                <Table
                  tableName={'Clientes'}
                  cols={columns}
                  rows={data}
                  buttons={buttons}
                  rowStyle={rowStyle}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Index
