import React, {useEffect, useRef, useState} from "react"
import CustomerNavbar from "../../../components/Navbars/CustomerNavbar"
import Shape from "../../../components/Shape"
import {Button, Col, Container, Row} from "reactstrap"
import SimpleFooter from "../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from '../../../components/Table'
import {useAppDataStore} from "../../../stores/appDataStore";

const Activity = observer(({}) => {

  const [data, setData] = useState([])
  const appDataStore = useAppDataStore()

  useEffect(() => {
    refreshData()
  }, [])

  const columns = [
    {
      field: 'activity',
      flex: 1,
      headerName: 'actividad'
    },
    {
      field: 'ip',
      headerName: 'IP',
    },
    {
      field: 'userAgent',
      headerName: 'Navegador',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha y hora',
      valueFormatter: Formatters.datetime

    }
  ]

  const refreshData = async () => {
    try {
      let {activity} = await appDataStore.getActivity()
      setData(activity)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <CustomerNavbar/>
      <main>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <Row>
              <Col lg={12} md={12}>
                <Table
                  tableName={'Mi actividad'}
                  cols={columns}
                  rows={data}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Activity
