import React, {useEffect, useRef, useState} from "react"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap"

// core components
import LoginNavbar from "components/Navbars/LoginNavbar.js"
import {observer} from "mobx-react"
import Alert from 'components/Alert'
import Shape from "../../components/Shape";
import {useAppDataStore} from "../../stores/appDataStore";
import {useNavigate} from "react-router";

const Login = observer(() => {

  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const appDataStore = useAppDataStore()
  const navigate = useNavigate()
  const mainRef = useRef()

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainRef.current.scrollTop = 0
  }, [])

  async function doLogin() {
    let res = await appDataStore.login(username, password)
    if (!res.error) {
      navigate('/customer/')
    } else {
      await Alert.fire(res.error)
    }
  }

  return (
    <>
      <LoginNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <Shape/>
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="pb-5">
                    <div className="text-center">
                      <div>Ingresa tus datos para iniciar sesión</div>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 py-lg-5">
                    <Form role="form">
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83"/>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Usuario" type="text" onChange={e => setUsername(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open"/>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Contraseña"
                            type="password"
                            autoComplete="off"
                            onChange={e => setPassword(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={async e => {
                            await doLogin()
                          }}
                        >
                          Iniciar sesión
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col xs="6">
                    <a
                      className="text-light"
                      href="#main"
                      onClick={e => e.preventDefault()}
                    >
                      <small>Olvidó la contraseña?</small>
                    </a>
                  </Col>
                  <Col className="text-right" xs="6">
                    <a className="text-light" href="#main" onClick={e => e.preventDefault()}>
                      <small>Crear cuenta</small>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
})

export default Login
