import React, {useEffect, useRef, useState} from "react"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col
} from "reactstrap"

// core components
import LoginNavbar from "components/Navbars/LoginNavbar.js"
import {observer} from "mobx-react"
import Shape from "../../../components/Shape";
import {useAppDataStore} from "../../../stores/appDataStore";
import {useNavigate, useParams} from "react-router";
import {Formik} from 'formik';
import {alert} from "../../../components/Alert";

const Login = observer(() => {
  const appDataStore = useAppDataStore()
  const navigate = useNavigate()
  const mainRef = useRef()
  const params = useParams()
  const [reseller, setReseller] = useState()

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainRef.current.scrollTop = 0
  }, [])

  const fetchReseller = async () => {
    const res = await appDataStore.getResellerByCode(params.code)
    if (res.result === 'success'){
      setReseller(res.reseller)
    }else{
      navigate('/')
    }
  }

  useEffect(() => {
    appDataStore.logout()
    if (params.code) fetchReseller()
  }, [params.code]);

  async function onSubmit(values) {
    const res = await appDataStore.createChildByReference(reseller.id, values.username, values.password, values.name, values.phone, values.email, values.docType, values.docNumber)
    if (res.result === 'error') return alert(res.error)
    await alert('El usuario se ha creado con éxito, ahora puede iniciar sesión')
    navigate('/')
  }

  return (
    <>
      <LoginNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <Shape/>
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="pb-3">
                    <div className="text-center">
                      <div>Registra tus datos para crear una cuenta</div>
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-1 py-lg-1">
                    <Formik initialValues={{}} onSubmit={onSubmit}>
                      {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="modal-body">
                            <Row>
                              <Col lg={6}>
                                <FormGroup>
                                  <label> Usuario </label>
                                  <Input
                                    name="username"
                                    className="form-control-alternative"
                                    placeholder="usuario"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.username}
                                  />
                                </FormGroup>

                                <FormGroup>
                                  <label> Contraseña </label>
                                  <Input
                                    name="password"
                                    className="form-control-alternative"
                                    placeholder="contraseña"
                                    type="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}/>
                                </FormGroup>

                                <FormGroup>
                                  <label> Nombre </label>
                                  <Input
                                    name="name"
                                    className="form-control-alternative"
                                    placeholder="Nombre"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name}/>
                                </FormGroup>
                                <FormGroup>
                                  <label> Teléfono </label>
                                  <Input
                                    name="phone"
                                    className="form-control-alternative"
                                    placeholder="teléfono"
                                    type="numeric"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone}/>
                                </FormGroup>
                              </Col>
                              <Col lg={6}>
                                <FormGroup>
                                  <label> Tipo documento </label>
                                  <Input
                                    name="docType"
                                    className="form-control-alternative"
                                    type="select"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.docType}>
                                    <option value=""></option>
                                    <option value="CC"> Cedula</option>
                                    <option value="NIT"> NIT</option>
                                    <option value="PEP"> P.E.P</option>
                                    <option value="OTRO"> OTRO</option>
                                  </Input>
                                </FormGroup>
                                <FormGroup>
                                  <label> Documento </label>
                                  <Input
                                    name="docNumber"
                                    className="form-control-alternative"
                                    placeholder="documento"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.docNumber}/>
                                </FormGroup>
                                <FormGroup>
                                  <label> Correo electrónico </label>
                                  <Input
                                    name="email"
                                    className="form-control-alternative"
                                    placeholder="correo electrónico"
                                    type="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}/>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Button color="primary" type="submit" size={'sm'} disabled={isSubmitting}>
                              Crear cuenta
                            </Button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
})

export default Login
