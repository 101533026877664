import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap";
import {useAppDataStore} from "../../../../stores/appDataStore";
import images from "../../../../stores/images";
import {toast} from "../../../../components/Alert";
import {Buffer} from "buffer"

const EditModal = ({data, toggle, visible, onSave}) => {
  const [id, setId] = useState()
  const [category, setCategory] = useState('')
  const [route, setRoute] = useState('')
  const [image, setImage] = useState('')
  const [description, setDescription] = useState('')
  const [enabled, setEnabled] = useState(true)
  const [position, setPosition] = useState(0)

  const appDataStore = useAppDataStore()

  useEffect(() => {
    if (!data) {
      setId(null)
      setCategory('')
      setImage('')
      setRoute('')
      setEnabled(true)
      setDescription('')
      setPosition(0)
      return
    }
    setId(data.id)
    setCategory(data.category)
    setImage(data.imageUrl)
    setRoute(data.route)
    setEnabled(data.enabled)
    setDescription(data.description)
    setPosition(data.position)
  }, [data])

  const onFileSelected = (e) => {
    let [file] = e.target.files
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      setImage(event.target.result)
    })
    reader.readAsDataURL(file)
  }

  const onSaveEvent = async () => {
    try {
      let res = {}

      let post = {
        id,
        category,
        image,
        route,
        description,
        enabled,
        position,
      }
      if (!id) post = {productCategories: [post]}
      res = await appDataStore.post(`/backoffice/categories/${id ? 'edit' : 'add'}`, post)
      if (res.result === "error") return await toast(res.error)
      if (res.productCategories && res.productCategories[0].success === false) return await toast(res.productCategories[0].result)

      if (onSave) onSave()
      toggle()
    } catch (err) {
      await toast(err.message)
    }
  }
  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Producto
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={6}>
          <FormGroup>
            <label> Nombre de la categoría </label>
            <Input
              className="form-control-alternative"
              value={category}
              placeholder="nombre de la categoría"
              onChange={e => setCategory(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Nombre de la ruta </label>
            <Input
              className="form-control-alternative"
              value={route}
              placeholder="nombre de la ruta"
              onChange={e => setRoute(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Descripción </label>
            <Input
              className="form-control-alternative"
              value={description}
              placeholder="Descripción"
              onChange={e => setDescription(e.target.value)}
            />
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                className="form-control-alternative"
                checked={enabled}
                type="checkbox"
                onChange={e => {
                  setEnabled(!enabled)
                }}
              />{' '}
              Habilitado
            </Label>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <label> Posición en lista </label>
            <Input
              value={position}
              className="form-control-alternative"
              placeholder="Posición en la lista"
              onChange={e => setPosition(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Imagen del producto </label>
            <Input
              accept={".png,.jpg,.jpeg"}
              style={{color: "#8898aa"}}
              type={"file"}
              className="form-control-alternative"
              placeholder="carrier"
              onChange={onFileSelected}
            />
          </FormGroup>
          <div>
            <img alt={"logo"} src={image || images.default} width={130} height={130}/>
          </div>
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={toggle}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={onSaveEvent}>
        Guardar
      </Button>
    </div>
  </Modal>
}
export default EditModal
