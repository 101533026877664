import React, {useEffect, useState} from "react"
import {Button, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap"
import {useAppDataStore} from "../../../../stores/appDataStore"
import {toast} from "../../../../components/Alert"
import images from "../../../../stores/images";

const AccountModal = ({data, toggle, visible, onSave}) => {
  const [id, setId] = useState()
  const [carrier, setCarrier] = useState("")
  const [product, setProduct] = useState("")
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [profiles, setProfiles] = useState("")
  const [canRenew, setCanRenew] = useState(false)
  const [vigency, setVigency] = useState("")
  const [notes, setNotes] = useState("")

  const appDataStore = useAppDataStore()

  const products = appDataStore.products.filter(product => product.route === 'digitalAccounts')

  useEffect(() => {
    const product = products.find(product => product.carrier === data.carrier) || products[0]
    setId(data.id)
    setProduct(product)
    setCarrier(data.carrier || (product ? product.carrier : ''))
    setUser(data.user || '')
    setPassword(data.password || '')
    setProfiles(data.profiles || '')
    setCanRenew(data.canRenew === true)
    setVigency(data.vigency || '')
    setNotes(data.notes || '')
  }, [data])

  const onSaveEvent = async () => {
    let res = {}
    if (id) {
      res = await appDataStore.post('/backoffice/digitalAccounts/edit', {
        id, carrier, user, password, profiles, canRenew, vigency, notes
      })
    } else {
      res = await appDataStore.post('/backoffice/digitalAccounts/add', {
        digitalAccounts: [
          {carrier, user, password, profiles, canRenew, vigency, notes}
        ]
      })
      let [resultAccount] = res.digitalAccounts
      res.error = resultAccount.result
      res.result = resultAccount.success ? 'success' : 'error'
    }
    if (res.result === 'error') return await toast(res.error)
    onSave()
    toggle()
  }

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Cuenta Digital
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={6}>
          <FormGroup>
            <label> Tipo </label>
            <div className={"d-flex"}>
              <Input
                value={carrier}
                className="form-control-alternative"
                type="select"
                onChange={e => {
                  const product = products.find(product => product.carrier === e.target.value)
                  setProduct(product)
                  setCarrier(e.target.value)
                }}
              >
                {
                  products.map(product => {
                    return <option value={product.carrier}>{product.name}</option>
                  })
                }
              </Input>
              {product ? <img style={{marginLeft: 5}} src={product.imageUrl || images.default} width={43} alt={product.carrier}/> : null}

            </div>
          </FormGroup>
          <FormGroup>
            <label> Nombre de usuario </label>
            <Input
              className="form-control-alternative"
              value={user}
              placeholder="usuario"
              onChange={e => setUser(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Contraseña </label>
            <Input
              className="form-control-alternative"
              placeholder="contraseña"
              value={password}
              type="text"
              onChange={e => setPassword(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <label> Perfiles </label>
            <Input
              value={profiles}
              className="form-control-alternative"
              placeholder="ej: 1-2-3-4"
              onChange={e => setProfiles(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Vigencia </label>
            <Input
              value={vigency}
              className="form-control-alternative"
              placeholder="días de vigencia"
              onChange={e => setVigency(e.target.value)}
            />
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                className="form-control-alternative"
                checked={canRenew}
                type="checkbox"
                onChange={e => {
                  setCanRenew(!canRenew)
                }}
              />{' '}
              Cuenta renovable
            </Label>
          </FormGroup>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label for="exampleText">Notas</Label>
            <Input
              value={notes}
              onChange={e => setNotes(e.target.value)}
              className="form-control-alternative"
              type="textarea" name="text"/>
          </FormGroup>
        </Col>
      </Row>

    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={toggle}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={onSaveEvent}>
        Guardar
      </Button>
    </div>
  </Modal>
}

export default AccountModal
