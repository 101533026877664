import React from "react";
import {Button, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {Formik} from 'formik';
import {useAppDataStore} from "../../../../stores/appDataStore";
import {confirm} from "../../../../components/Alert";

const UserModal = ({user, toggle, visible, onSave}) => {
  const appDataStore = useAppDataStore()
  if (!user) return
  const initialValues = {
    username: '',
    password: '',
    name: '',
    phone: '',
    email: '',
    rol: 'reseller',
    docType: 'CC',
    docNumber: '',
    ...user
  }
  const onSubmit = (values, {setSubmitting}) => {
    onSave(values)
    setSubmitting(false)
  }

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Usuario {user.name ? user.name : 'nuevo'}
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <label> Usuario </label>
                  <Input
                    name="username"
                    disabled={!!user.username}
                    className="form-control-alternative"
                    placeholder="usuario"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                  />
                </FormGroup>
                {!user.username ?
                  <FormGroup>
                    <label> Contraseña </label>
                    <Input
                      name="password"
                      className="form-control-alternative"
                      placeholder="contraseña"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}/>
                  </FormGroup>
                  : null}
                <FormGroup>
                  <label> Nombre </label>
                  <Input
                    name="name"
                    defaultValue={user.name}
                    className="form-control-alternative"
                    placeholder="Nombre"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}/>
                </FormGroup>
                <FormGroup>
                  <label> Teléfono </label>
                  <Input
                    name="phone"
                    defaultValue={user.phone}
                    className="form-control-alternative"
                    placeholder="teléfono"
                    type="numeric"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}/>
                </FormGroup>
              </Col>
              <Col lg={6}>
                {!user.rol ?
                  <FormGroup>
                    <label> Perfil </label>
                    <Input
                      name="rol"
                      defaultValue={user.rol}
                      className="form-control-alternative"
                      type="select"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rol}>
                      <option value="reseller"> Distribuidor</option>
                      <option value="pos"> Punto de venta</option>
                    </Input>
                  </FormGroup>
                  : null}
                <FormGroup>
                  <label> Tipo documento </label>
                  <Input
                    name="docType"
                    defaultValue={user.docType}
                    className="form-control-alternative"
                    type="select"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.docType}>
                    <option value="CC"> Cedula</option>
                    <option value="NIT"> NIT</option>
                    <option value="PEP"> P.E.P</option>
                    <option value="OTRO"> OTRO</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <label> Documento </label>
                  <Input
                    name="docNumber"
                    defaultValue={user.docNumber}
                    className="form-control-alternative"
                    placeholder="documento"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.docNumber}/>
                </FormGroup>
                <FormGroup>
                  <label> Correo electrónico </label>
                  <Input
                    name="email"
                    defaultValue={user.email}
                    className="form-control-alternative"
                    placeholder="correo electrónico"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}/>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            {user.id && appDataStore?.customer?.rol === 'admin' ? <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              size={'sm'}
              disabled={isSubmitting}
              onClick={(e) => {
                confirm(`se eliminará el usuario ${user.username}`).then(async res => {
                  if (res.value) {
                    await appDataStore.deleteChild(user.id)
                    handleSubmit()
                  } else {
                    return e.preventDefault()
                  }
                })
              }}
            >
              Eliminar Usuario
            </Button> : null}
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              size={'sm'}
              onClick={() => toggle()}
            >
              Cancelar
            </Button>
            <Button color="primary" type="submit" size={'sm'} disabled={isSubmitting}>
              Guardar
            </Button>
          </div>
        </form>
      )}
    </Formik>
  </Modal>
}
export default UserModal