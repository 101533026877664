import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const Alert = withReactContent(Swal)

export const toast = async (message) => {
  return Alert.fire({
    position: 'center',
    icon: 'error',
    title: message,
    showConfirmButton: false,
    timer: 1500
  })
}

export const confirm = async (message) => {
  return Alert.fire({
    position: 'center',
    icon: 'question',
    title: message,
    showCancelButton:true,
    showConfirmButton: true,
    confirmButtonText: 'Aceptar',
    cancelButtonText: 'Cancelar'
  })
}
export const alert = async (message) =>{
  return Alert.fire({
    position: 'center',
    icon: 'info',
    title: message,
    showConfirmButton: true,
    confirmButtonText: 'Aceptar',
  })
}
export default Alert
