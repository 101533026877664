import React, {useEffect, useRef, useState} from "react"

// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Label
} from "reactstrap"

// core components
import CustomerNavbar from "../../components/Navbars/CustomerNavbar"
import SimpleFooter from "components/Footers/SimpleFooter.js"
import {observer} from "mobx-react"
import CarrierLogo from 'components/CarrierLogo'
import Shape from 'components/Shape'
import {Alert, toast} from 'components/Alert'
import {useAppDataStore} from "../../stores/appDataStore"
import Spinner from "reactstrap/es/Spinner"
import styles from "./styles.css"

const defaultAmountValues = [1000, 2000, 3000, 4000, 5000, 6000, 10000, 20000, 30000, 50000]
const defaultMinValue = 1000
const defaultMaxValue = 50000
const defaultFixedAmount = false

const CarriersSelector = ({carriers, onChange}) => {
  const [selectedCarrier, setSelectedCarrier] = useState(null)

  const selectCarrier = (carrier) => {
    setSelectedCarrier(carrier)
    if (onChange) onChange(carrier)
  }

  return <Row>
    <Col lg="12 text-center">
      {
        carriers.map((carrier) => {
          let selected = selectedCarrier && selectedCarrier.carrier === carrier.carrier
          if (carrier.enabled)
            return (
              <CarrierLogo
                key={carrier.id}
                carrier={carrier}
                selected={selected}
                style={selected ? {border: '#FFFFFF'} : {}}
                onClick={selectedCarrier => selectCarrier(selectedCarrier)}
              />)
        })}
    </Col>
  </Row>
}

const InputBlock = ({selectedCarrier, appDataStore}) => {
  const [amount, setAmount] = useState('')
  const [number, setNumber] = useState('')
  const [busy, setBusy] = useState(false)
  const [minValue, setMinValue] = useState(defaultMinValue)
  const [maxValue, setMaxValue] = useState(defaultMaxValue)
  const [fixedAmount, setFixedAmount] = useState(defaultFixedAmount)
  const [amountValues, setAmountValues] = useState(defaultAmountValues)

  const amountInputRef = useRef()
  const numberInputRef = useRef()

  useEffect(() => {
    if (selectedCarrier) {
      setAmount('')
      setNumber('')
      setFixedAmount(selectedCarrier.fixedAmount || defaultFixedAmount)
      setAmountValues(selectedCarrier.amountValues || defaultAmountValues)
      setMinValue(selectedCarrier.minValue || defaultMinValue)
      setMaxValue(selectedCarrier.maxValue || defaultMaxValue)
      amountInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
      amountInputRef.current.focus()
    }
  }, [selectedCarrier])
  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      run().then()
    }
  }

  const run = async () => {
    if (!selectedCarrier) return await toast('Debe seleccionar el operador')
    if (!amount.validateAmount(minValue, maxValue)) return await toast('el valor ingresado es inválido')
    if (!number.validateMin()) return await toast('El número ingresado es inválido')

    let res = await Alert.fire({
      title: <CarrierLogo carrier={selectedCarrier}/>,
      html: <>Número: <b>{number}</b><br/>Valor: <b>{Object.cash(amount)}</b></>,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Recargar',
      cancelButtonText: 'Cancelar',
    })

    if (res && res.value) {
      setBusy(true)
      let res = await appDataStore.post('/public-api/topup', {productId: selectedCarrier.id, number, amount})
      setBusy(false)

      if (res.error) return toast(res.error)
      await appDataStore.updateCustomer()

      await Alert.fire({
        position: 'center',
        icon: 'success',
        title: res.customerResponse || 'Venta realizada con éxito',
        showConfirmButton: true,
      })
      this.setState({number: '', amount: '', selectedCarrier: null})
    }
  }

  const selectAmount = (amount) => {
    setAmount(amount)
    numberInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    numberInputRef.current.focus()
  }

  return (
    <div>
      <Row className={'text-center d-none d-sm-block topup-values'}>
        <Col>
          {amountValues.map(
            amount => {
              return (
                <Button
                  className={'topup-button'}
                  key={amount}
                  onClick={() => selectAmount(amount)}>
                  {amount.cash()}
                </Button>)
            })
          }
        </Col>
      </Row>
      <Row className="justify-content-center text-center">
        <Col lg={3} sm={8} xs={8} className="align-items-center topup-col-centered">
          <CarrierLogo carrier={selectedCarrier}/>
          <br/>
          <Label>Valor</Label>
          <Input placeholder={'Valor'} value={amount}
                 disabled={fixedAmount || busy}
                 innerRef={amountInputRef}
                 onChange={e => setAmount(e.target.value)}
                 onKeyUp={e => handleOnKeyUp(e)}
          />
          <Label>Número</Label>

          <Input placeholder={'Número'} value={number}
                 innerRef={numberInputRef}
                 onChange={e => setNumber(e.target.value)}
                 onKeyUp={e => handleOnKeyUp(e)}
                 disabled={busy}
          />
          <br/>
          <Button className={'primary'} onClick={() => run()} disabled={busy}>
            {busy ?
              <Spinner
                as="span"
                type="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : null}
            &nbsp;Recargar
          </Button>
        </Col>
      </Row>
    </div>)
}

const Topup = observer(() => {

  const appDataStore = useAppDataStore()
  const carriers = appDataStore.products.filter(product => product.route === 'topup')
  console.log(appDataStore.products)
  const [selectedCarrier, setSelectedCarrier] = useState(null)

  const mainRef = useRef()


  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainRef.current.scrollTop = 0
  }, [])

  return (
    <>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container fluid className="py-lg-md d-flex">
            <div className="col px-0">
              <CarriersSelector carriers={carriers} onChange={(carrier) => setSelectedCarrier(carrier)}/>
              <InputBlock appDataStore={appDataStore} selectedCarrier={selectedCarrier}/>
            </div>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </>
  )

})

export default Topup
