import React, {useEffect, useMemo, useRef, useState} from "react"
import {Button, Col, Modal, Row} from "reactstrap"
import {useAppDataStore} from "../../../../stores/appDataStore"
import {toast} from "../../../../components/Alert"
import {AgGridReact} from "ag-grid-react"
import {AG_GRID_LOCALE_ES, Formatters} from "../../../../components/Table"

const AssocModal = ({pkg = null, toggle, visible, onSave}) => {
  const [providers, setProviders] = useState([])
  const [dataSource, setDataSource] = useState([])
  const gridRef = useRef()

  const appDataStore = useAppDataStore()
  const containerStyle = {marginTop: '10px', borderRadius: '0.75rem', padding: 3, backgroundColor: '#FFFFFF'}

  const refreshData = async () => {
    const {providers} = await appDataStore.get('/backoffice/providers/list')
    const {packagesAssoc} = await appDataStore.get(`/backoffice/providers/assoc?packageId=${pkg.id}`)

    const dataSource = []
    for (const provider of providers) {
      const packageAssoc = packagesAssoc.find(assoc => assoc.provider === provider)
      dataSource.push({provider, pkg, updatedAt: packageAssoc?.updatedAt, providerId: packageAssoc?.providerId || ''})
    }
    setDataSource(dataSource)
    setProviders(providers)
  }
  useEffect(() => {
    if (pkg) refreshData()
  }, [pkg])

  const columns = [
    {
      field: 'provider',
      flex: 1,
      headerName: 'Proveedor',
      valueFormatter: Formatters.cash
    },
    {
      field: 'providerId',
      flex: 1,
      headerName: 'ID Paquete',
      editable: true,
      cellStyle: {color: 'green', fontWeight: 'bold'}
    },
    {
      field: 'updatedAt',
      flex: 1,
      headerName: 'Ultima modificación',
      valueFormatter: Formatters.datetime
    }
  ]

  const innerDefaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true
  }))

  const onEdit = async ({oldValue, newValue, data}) => {
    if (oldValue === newValue) return
    const res = await appDataStore.post('/backoffice/providers/assoc/set', data)
    await refreshData()
  }

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title"
          id="userModal">
        Asociar paquete a proveedores <b>{pkg?.carrier}</b>
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <h6>{pkg.name}</h6>
      </Row>
      <Row>
        <Col>
          <div style={containerStyle}>
            <div className="ag-theme-material" style={{width: '100%', height: 500}}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                enableCellTextSelection={false}
                columnDefs={columns} // Column Defs for Columns
                defaultColDef={innerDefaultColDef} // Default Column Properties
                localeText={AG_GRID_LOCALE_ES}
                rowData={dataSource} // Row Data for Rows
                onCellEditingStopped={onEdit}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="primary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={toggle}
      >
        Cerrar
      </Button>
    </div>
  </Modal>
}
export default AssocModal
