import React from "react"
import CustomerNavbar from "../../components/Navbars/CustomerNavbar"
import Shape from "../../components/Shape"
import {Container, Row} from "reactstrap"
import SimpleFooter from "../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"

const Wallets = observer({

  render() {
    return (
      <>
        <CustomerNavbar/>
        <main ref="main">
          <section className="section section-shaped section-lg">
            <Shape/>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row></Row>
              </div>
            </Container>
          </section>
        </main>
        <SimpleFooter/>
      </>
    )
  }
})

export default Wallets
