import React, {useEffect, useState} from "react"
import {Badge, Button, Col, Input, Modal, Row} from "reactstrap"
import Table, {Formatters} from "../../../../components/Table"
import {read, writeFile, utils} from 'xlsx'
import Spinner from "reactstrap/es/Spinner"
import {useAppDataStore} from "../../../../stores/appDataStore"
import {toast} from "../../../../components/Alert"


const MassiveModal = ({toggle, visible, onSave}) => {
  const [previewData, setPreviewData] = useState([])
  const [busy, setBusy] = useState(false)
  const [saved, setSaved] = useState(false)
  const appDataStore = useAppDataStore()

  useEffect(() => {
    setPreviewData([])
  }, [visible])
  const excelHeader = ["carrier", "user", "password", "profiles", "vigency", "canRenew", "notes"]

  const columns = [
    {
      field: 'carrier',
      flex: 1,
      maxWidth: 80,
      headerName: '',
      cellRenderer: Formatters.image
    },
    {
      field: 'carrier',
      flex: 1,
      headerName: 'Tipo',
    },
    {
      field: 'user',
      flex: 1,
      headerName: 'Usuario'
    },
    {
      field: 'password',
      maxWidth: 150,
      headerName: 'contraseña',
    },
    {
      field: 'profiles',
      headerName: 'perfiles',
      maxWidth: 150,
      flex: 1,
    },
    {
      field: 'vigency',
      headerName: 'vigencia',
      flex: 1,
    },
    {
      field: 'canRenew',
      headerName: 'Renovable?',
      flex: 1,
      cellRenderer: Formatters.boolean
    },
    {
      field: 'notes',
      headerName: 'Notas',
      flex: 1,
    },
    {
      field: 'result',
      headerName: 'Resultado',
      flex: 1,
      minWidth: 100,
      cellRenderer: ({data}) => {
        if (data.success)
          return <Badge color={'success'}>{data.result}</Badge>
        return <Badge color={'danger'}>{data.result}</Badge>
      }
    },
  ]

  const onFileSelected = (e) => {
    let [file] = e.target.files
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      setBusy(false)
      setSaved(false)

      let [fileHeader, fileData] = event.target.result.split(',')

      let workbook = read(fileData, {type: 'base64'})

      let [sheetName] = workbook.SheetNames

      const worksheet = workbook.Sheets[sheetName]

      let jsonData = utils.sheet_to_json(worksheet, {
        skipHeader: true,
        header: excelHeader,
      })

      let [header] = jsonData
      if (header.carrier.toLowerCase() === 'carrier') jsonData.shift()
      for (let row of jsonData) {
        row.carrier = row.carrier.toLowerCase()
      }
      setPreviewData(jsonData)
    })
    reader.readAsDataURL(file)
  }

  const onSaveHandler = async () => {
    setBusy(true)
    try {
      let res = await appDataStore.post('/backoffice/digitalAccounts/add', {digitalAccounts: previewData})
      if (res.result === 'success') {
        setPreviewData(res.digitalAccounts)
        setSaved(true)
        if (onSave) onSave()
      } else {
        await toast(res.error)
      }

    } catch (err) {
      await toast(err.message)
    }
    setBusy(false)
  }

  const downloadExample = () => {
    let worksheet = utils.json_to_sheet([], {header: excelHeader})
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, "cuentas")
    writeFile(workbook, 'ejemplo_cuentas.xlsx')
  }

  return <Modal
    backdrop={false}
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="xl"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Subir cuentas masivas
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col>
          <Input
            style={{color:'#000000'}}
            type="file"
            name="file"
            onChange={onFileSelected}
            accept={".xls,.xlsx,.csv,.ods"}
          />

        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            cols={columns}
            rows={previewData}
            searchTable={false}
            exportButton={false}
          />
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={downloadExample}
      >
        Descargar formato
      </Button>
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={() => toggle()}
      >
        Cerrar
      </Button>
      <Button disabled={busy || saved} color="primary" type="button" size={'sm'} onClick={onSaveHandler}>&nbsp;
        {busy ?
          <Spinner
            as="span"
            type="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          /> : null}
        Guardar
      </Button>
    </div>
  </Modal>
}

export default MassiveModal
