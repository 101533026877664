import React, {useEffect, useState} from "react"
import {Link} from "react-router-dom"
import {observer} from "mobx-react"

// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js"
// reactstrap components
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Media,
  Modal,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledTooltip
} from "reactstrap"

import {useAppDataStore} from "../../stores/appDataStore"
import {alert} from "../Alert"

const UserModal = observer(({toggle, visible, onSave}) => {

  const [name, setName] = useState()
  const [docType, setDocType] = useState()
  const [docNumber, setDocNumber] = useState()
  const [phone, setPhone] = useState()
  const [email, setEmail] = useState()

  const appDataStore = useAppDataStore()
  const [user, setUser] = useState({})

  const handleSave = async () => {
    let res = await appDataStore.post('/public-api/updateProfile', {name, docType, docNumber, phone, email})
    if (res.result === 'error') return alert(res.error)
    if (onSave) onSave()
  }

  useEffect(() => {
    setUser(appDataStore.customer)
  }, [appDataStore.customer])

  useEffect(() => {
    setName(user.name)
    setDocType(user.docType)
    setDocNumber(user.docNumber)
    setPhone(user.phone)
    setEmail(user.email)
  }, [user])

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Editar Datos
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={6}>
          <FormGroup>
            <label> Usuario </label>
            <Input
              disabled={true}
              autoComplete="off"
              className="form-control-alternative"
              defaultValue={user.username}
              placeholder="usuario"
            />
          </FormGroup>
          <FormGroup>
            <label> Nombre </label>
            <Input
              autoComplete="off"
              defaultValue={user.name}
              className="form-control-alternative"
              placeholder="Nombre"
              onChange={e => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Teléfono </label>
            <Input
              defaultValue={user.phone}
              className="form-control-alternative"
              placeholder="teléfono"
              type="numeric"
              onChange={e => setPhone(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>

          <FormGroup>
            <label> Tipo documento </label>
            <Input
              defaultValue={user.docType}
              className="form-control-alternative"
              type="select"
              onChange={e => setDocType(e.target.value)}
            >
              <option value="CC"> Cedula</option>
              <option value="NIT"> NIT</option>
              <option value="PEP"> P.E.P</option>
              <option value="OTRO"> OTRO</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <label> Documento </label>
            <Input
              defaultValue={user.docNumber}
              className="form-control-alternative"
              placeholder="documento"
              onChange={e => setDocNumber(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Correo electrónico </label>
            <Input
              defaultValue={user.email}
              className="form-control-alternative"
              placeholder="correo electrónico"
              type="email"
              onChange={e => setEmail(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
      {user.code && (user.rol === 'reseller' || user.rol === 'admin') ? <Row>
        <Col>
          <FormGroup>
            <label> URL Referidos </label>
            <Input
              name="email"
              defaultValue={`https://netcarga.com.co/signin/${user.code}`}
              className="form-control-alternative"
              placeholder="URL referidos"
              disabled={true}
            />
          </FormGroup>
        </Col>
      </Row> : null}

    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={() => toggle()}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={handleSave}>
        Guardar
      </Button>
    </div>
  </Modal>
})

const PasswordModal = observer(({toggle, visible, forced, onSave}) => {

  const [currentPassword, setCurrentPassword] = useState()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const appDataStore = useAppDataStore()

  const handleSave = async () => {
    let res = await appDataStore.post('/public-api/changePassword', {password, currentPassword, confirmPassword})
    if (res.result === 'error') return alert(res.error)
    if (onSave) onSave()
  }

  useEffect(() => {
    setCurrentPassword('')
    setPassword('')
    setConfirmPassword('')
  }, [visible])

  return <Modal
    backdrop={!forced}
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Cambio de contraseña
      </h5>
      {!forced ? <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button> : null
      }
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={12}>
          <FormGroup>
            <label> Contraseña actual </label>
            <Input
              type={'password'}
              autoComplete="off"
              className="form-control-alternative"
              value={currentPassword}
              placeholder="Contraseña actual"
              onChange={e => setCurrentPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Nueva contraseña </label>
            <Input
              type={'password'}
              autoComplete="off"
              className="form-control-alternative"
              value={password}
              placeholder="Nueva contraseña"
              onChange={e => setPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Confirmar nueva contraseña </label>
            <Input
              type={'password'}
              autoComplete="off"
              className="form-control-alternative"
              value={confirmPassword}
              placeholder="Confirmar nueva contraseña"
              onChange={e => setConfirmPassword(e.target.value)}

            />
          </FormGroup>
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      {!forced ? <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={() => toggle()}
      >
        Cancelar
      </Button> : null
      }
      <Button color="primary" type="button" size={'sm'} onClick={handleSave}>
        Guardar
      </Button>
    </div>
  </Modal>
})

const CustomerNavbar = observer(() => {

  const [collapseClasses, setCollapseClasses] = useState("")
  const appDataStore = useAppDataStore()
  const [categories, setCategories] = useState(appDataStore.categories)
  const [editProfileModalVisible, setEditProfileModalVisible] = useState(false)
  const [editPasswordModalVisible, setEditPasswordModalVisible] = useState(false)

  const {customer} = appDataStore

  const {rol, username, balance, balance1, balanceInUse} = customer

  useEffect(() => {

    appDataStore.updateProducts().then(() => {
      setCategories(appDataStore.categories)
    })

    let headroom = new Headroom(document.getElementById("navbar-main"))
    // initialise
    headroom.init()
  }, [])

  let onExiting = () => {
    setCollapseClasses("collapsing-out")
  }

  let onExited = () => {
    setCollapseClasses("")
  }

  const toggleEditProfileModalVisible = () => {
    setEditProfileModalVisible(!editProfileModalVisible)
  }

  const toggleEditPasswordModalVisible = () => {
    setEditPasswordModalVisible(!editPasswordModalVisible)
  }

  const onProfileSaved = async () => {
    await appDataStore.updateCustomer()
    setEditProfileModalVisible(false)
    setEditPasswordModalVisible(false)
  }
  return (
    <header className="header-global">
      <UserModal visible={editProfileModalVisible} onSave={onProfileSaved} toggle={toggleEditProfileModalVisible}/>
      <PasswordModal visible={editPasswordModalVisible || !customer.passwordChanged} onSave={onProfileSaved}
                     toggle={toggleEditPasswordModalVisible} forced={!customer.passwordChanged}/>
      <Navbar
        className="navbar-main navbar-transparent navbar-light headroom"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand className="mr-lg-5" to={'/customer/'} tag={Link}>
            <img
              style={{height: '50px'}}
              alt="Netcarga - soluciones integrales"
              src={require("assets/img/brand/logo.png")}
            />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar_global">
            <span className="navbar-toggler-icon"/>
          </button>
          <UncontrolledCollapse
            toggler="#navbar_global"
            navbar
            className={collapseClasses}
            onExiting={onExiting}
            onExited={onExited}
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="Netcarga"
                      src={require("assets/img/brand/logo.png")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar_global">
                    <span/>
                    <span/>
                  </button>
                </Col>
              </Row>
            </div>

            <Nav className="navbar-nav-hover align-items-lg-center" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-ui-04 d-lg-none mr-1"/>
                  <span className="nav-link-inner--text">Productos</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-xl">
                  <div className="dropdown-menu-inner">
                    {
                      categories.map(item => {
                        return (
                          <Link key={item.id} replace to={`/customer/products/${item.route}`}>
                            <Media
                              className="d-flex align-items-center"
                              target={item.target ? item.target : '_self'}
                            >
                              <div
                                className={"icon icon-shape icon-sm rounded-circle text-white bg-gradient-" + (item.color || 'gray-dark')}>
                                <i className="ni ni-ui-04"/>
                              </div>
                              <div className="ml-3">
                                <h6 className={"heading mb-md-1 text-" + (item.color || 'gray-dark')}>
                                  {item.category}
                                </h6>
                                <p className={"description d-none d-md-inline-block mb-0"}>
                                  {item.description}
                                </p>
                              </div>
                            </Media>
                          </Link>
                        )
                      })}
                  </div>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle nav>
                  <i className="ni ni-collection d-lg-none mr-1"/>
                  <span className="nav-link-inner--text">Reportes</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem to="/customer/reports/sales" tag={Link}>
                    Reporte de ventas de productos
                  </DropdownItem>
                  <DropdownItem to="/customer/reports/purchases" tag={Link}>
                    Reporte de compras de saldo
                  </DropdownItem>
                  {rol === 'admin' || rol === 'reseller' ?
                    <DropdownItem to="/customer/reports/transfers" tag={Link}>
                      Reporte de traslados de saldo
                    </DropdownItem> : null}

                  <DropdownItem to="/customer/reports/consignments" tag={Link}>
                    Reporte de consignaciones
                  </DropdownItem>
                  <DropdownItem to="/customer/reports/profits" tag={Link}>
                    Reporte de comisiones
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {rol === 'admin' || rol === 'reseller' ?
                <UncontrolledDropdown nav>
                  <DropdownToggle nav>
                    <i className="ni ni-collection d-lg-none mr-1"/>
                    <span className="nav-link-inner--text">Distribución</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to="/customer/distribution/users" tag={Link}>
                      Clientes
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown> : null}
              {rol === 'admin' ?
                <UncontrolledDropdown nav>
                  <DropdownToggle nav>
                    <i className="ni ni-collection d-lg-none mr-1"/>
                    <span className="nav-link-inner--text">Administración</span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to="/customer/administration/digitalAccounts" tag={Link}>
                      Cuentas digitales
                    </DropdownItem>
                    <DropdownItem to="/customer/administration/Products" tag={Link}>
                      Productos
                    </DropdownItem>
                    <DropdownItem to="/customer/administration/Categories" tag={Link}>
                      Categorias de Productos
                    </DropdownItem>
                    <DropdownItem to="/customer/administration/Packages" tag={Link}>
                      Paquetes
                    </DropdownItem>
                    <DropdownItem to="/customer/administration/Providers" tag={Link}>
                      Proveedores
                    </DropdownItem>
                    <DropdownItem to="/customer/administration/reports" tag={Link}>
                      Reportes
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                : null
              }

            </Nav>
            <Nav className="align-items-lg-center ml-lg-auto" navbar>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  href="#"
                  id="tooltip112445449"
                >
                  <span
                    onClick={() => appDataStore.setBalanceInUse(0)}
                    className={balanceInUse === 0 ? "item-selected" : ""}>saldo: <b>{Object.cash(balance)}</b></span>
                </NavLink>
                <UncontrolledTooltip delay={0} target="tooltip112445449">
                  Saldo principal
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  href="#"
                  id="tooltip1124454499"
                >
                  <span
                    onClick={() => appDataStore.setBalanceInUse(1)}
                    className={balanceInUse === 1 ? "item-selected" : ""}>comisiones: <b>{Object.cash(balance1)}</b></span>
                </NavLink>
                <UncontrolledTooltip delay={0} target="tooltip1124454499">
                  Comisiones
                </UncontrolledTooltip>

                <NavLink className={'d-inline d-lg-none'} href="/auth/logout">
                  <i className="ni ni-button-power pr-1"/>
                  <span>Cerrar sesión</span>
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {username}
                    </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="">
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Mi cuenta</h6>
                  </DropdownItem>
                  <DropdownItem onClick={toggleEditProfileModalVisible}>
                    <i className="ni ni-circle-08"/>
                    <span>Editar datos</span>
                  </DropdownItem>
                  <DropdownItem onClick={toggleEditPasswordModalVisible}>
                    <i className="ni ni-key-25"/>
                    <span>Cambiar contraseña</span>
                  </DropdownItem>
                  <DropdownItem to="/customer/account/sessions" tag={Link}>
                    <i className="ni ni-bullet-list-67"/>
                    <span>Sesiones</span>
                  </DropdownItem>
                  <DropdownItem to="/customer/account/activity" tag={Link}>
                    <i className="ni ni-calendar-grid-58"/>
                    <span>Mi actividad</span>
                  </DropdownItem>
                  <DropdownItem to="/customer/account/support" tag={Link}>
                    <i className="ni ni-support-16"/>
                    <span>Soporte</span>
                  </DropdownItem>
                  <DropdownItem divider/>
                  <DropdownItem href="/auth/logout">
                    <i className="ni ni-button-power"/>
                    <span>Cerrar sesión</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </header>
  )
})

export default CustomerNavbar
