import React from "react"
import {createRoot} from 'react-dom/client'
import {BrowserRouter, Navigate, Route, useNavigate} from "react-router-dom"
import {AppDataProvider, appDataStore} from 'stores/appDataStore'
import 'util/extend-prototypes'

import "assets/vendor/nucleo/css/nucleo.css"
import "assets/vendor/font-awesome/css/font-awesome.min.css"
import "assets/scss/argon-design-system-react.scss?v1.1.0"

import Login from "views/public/Login.js"
import Logout from "views/public/Logout.js"
import Accounts from "views/public/accounts/"
import SignIn from "./views/public/signin"

import Topup from "views/customer/Topup.js"
import Packages from "views/customer/Packages.js"

import {DigitalAccounts, DigitalPins, Gambling, GenericDigitalAccounts} from "./views/customer/GenericProducts"

import Insurance from "./views/customer/Insurance"
import InvoicePayments from "./views/customer/InvoicePayments"
import Wallets from "./views/customer/Wallets"
import Index from "./views/customer/"
import Purchases from "./views/customer/Reports/Purchases"
import Sales from "./views/customer/Reports/Sales"
import Transfers from "./views/customer/Reports/Transfers"
import Consignments from "./views/customer/Reports/Consignments"
import Users from "./views/customer/Distribution/Users"
import AdmProducts from "./views/customer/Administration/Products"
import AdmCategories from "./views/customer/Administration/Categories"

import AdmPackages from "./views/customer/Administration/Packages"
import AdmPackagesCategories from "./views/customer/Administration/PackagesCategories"

import AdmDigitalAccounts from "./views/customer/Administration/DigitalAccounts"
import AdmProviders from "./views/customer/Administration/Providers"
import AdmSales from "./views/customer/Administration/Reports/AdmSales";

import {Outlet, Routes} from "react-router"
import {observer} from "mobx-react";
import Profits from "./views/customer/Reports/Profits";

import Sessions from "./views/customer/Account/sessions";
import Activity from "./views/customer/Account/activity";
import Support from "./views/customer/Account/Support";

import "./assets/base.css"


const PrivateRoute = observer(() => {
  if (appDataStore.customer === null || !appDataStore.customer?.id) {
    return <Navigate to="/auth" replace/>
  }
  return <Outlet/>
})

const AdmRoute = observer(() => {
  if (appDataStore?.customer?.rol !== 'admin') {
    return <Navigate to="/" replace/>
  }
  return <Outlet/>
})

const App = () => {
  const props = {navigate: useNavigate()}
  return (
    <AppDataProvider>
      <Routes>
        <Route path="/" element={<Navigate replace to="/customer/"/>}/>

        <Route path="/customer" element={<PrivateRoute/>}>
          <Route index element={<Index/>}/>
          <Route path="products">
            <Route path="topup" element={<Topup/>}/>
            <Route path="packages" element={<Packages/>}/>
            <Route path="gambling" element={<Gambling route={'gambling'}/>}/>
            <Route path="digitalAccounts" element={<DigitalAccounts route={'digitalAccounts'}/>}/>
            <Route path="genericDigitalAccounts" element={<GenericDigitalAccounts route={'genericDigitalAccounts'}/>}/>
            <Route path="digitalPin" element={<DigitalPins route={'digitalPin'}/>}/>
            <Route path="insurance" element={<Insurance/>}/>
            <Route path="invoicePayments" element={<InvoicePayments/>}/>
            <Route path="wallets" element={<Wallets/>}/>
          </Route>
          <Route path="reports">
            <Route index path="purchases" element={<Purchases/>}/>
            <Route path="sales" element={<Sales/>}/>
            <Route path="transfers" element={<Transfers/>}/>
            <Route path="consignments" element={<Consignments/>}/>
            <Route path="profits" element={<Profits/>}/>
          </Route>

          <Route path="distribution">
            <Route path="users" element={<Users/>}/>
          </Route>

          <Route path="administration" element={<AdmRoute/>}>
            <Route path="products" element={<AdmProducts/>}/>
            <Route path="categories" element={<AdmCategories/>}/>
            <Route path="packages" element={<AdmPackages/>}/>
            <Route path="packagesCategories" element={<AdmPackagesCategories/>}/>
            <Route path="digitalAccounts" element={<AdmDigitalAccounts/>}/>
            <Route path="providers" element={<AdmProviders/>}/>
            <Route path="reports" element={<AdmSales/>}/>
          </Route>

          <Route path="account">
            <Route path="sessions" element={<Sessions/>}/>
            <Route path="activity" element={<Activity/>}/>
            <Route path="support" element={<Support/>}/>
          </Route>
        </Route>

        <Route path="/auth">
          <Route index element={<Navigate replace to="login"/>}/>
          <Route path="login" element={<Login {...props}/>}/>
          <Route path="logout" element={<Logout/>}/>
        </Route>

        <Route path="/accounts">
          <Route index element={<Navigate replace to="index"/>}/>
          <Route path={':code'} element={<Accounts {...props}/>}/>
        </Route>

        <Route path="/signin">
          <Route index element={<Navigate replace to="index"/>}/>
          <Route path={':code'} element={<SignIn {...props}/>}/>
        </Route>

        <Route path="*" element={<Navigate replace to="/"/>}/>
      </Routes>
    </AppDataProvider>)
}

const root = createRoot(document.getElementById('root'))

root.render(<React.StrictMode>
  <BrowserRouter>
    <App/>
  </BrowserRouter>
</React.StrictMode>)

