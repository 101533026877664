import React, {useEffect, useRef, useState} from "react"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap"

// core components
import LoginNavbar from "components/Navbars/LoginNavbar.js"
import {observer} from "mobx-react"
import Alert from 'components/Alert'
import Shape from "../../../components/Shape";
import {appDataStore, useAppDataStore} from "../../../stores/appDataStore";
import {useNavigate, useParams} from "react-router";
import {Buffer} from "buffer";
import moment from "moment";

const CaptureData = ({onSubmit}) => {
  const [number, setNumber] = useState()
  return <Row className="justify-content-center">
    <Col lg={3}>
      <Card className="shadow border-1">
        <CardBody>
          <div>
            <Row>
              <Col>
                <Input value={number} onChange={(e) => setNumber(e.target.value)} placeholder={"Número de teléfono"}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="align-center pt-4">
                  <Button onClick={() => onSubmit(number)}>Consultar</Button>
                </div>

              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </Col>
  </Row>
}

const AccountData = ({data}) => {
  const vigency = data.vigency - moment().diff(data.soldAt,'days')
  const vigencyLabel = vigency > 0 ? `${vigency} días` : <b>VENCIDA</b>
  return <Row className="justify-content-center">
    <Col md="6" sm="8" lg="6" xl="5">
      <Card className="shadow border-1">
        <CardBody>
          <Row>
            <div className={"align-center pb-5"}>
              {data.product?.imageUrl ?
                <img className={"rounded-lg"} alt={data.product?.name} width={200} src={data.product.imageUrl}/> : null}
            </div>
          </Row>
          <Row>
            <Col className="">
              Cuenta Adquirida
            </Col>
            <Col className="">
              {data.product?.name}
            </Col>
          </Row>
          <Row>
            <Col className="">
              Usuario
            </Col>
            <Col className="">
              {data.user}
            </Col>
          </Row>
          <Row>
            <Col className="">
              Contraseña
            </Col>
            <Col className="">
              {data.password}
            </Col>
          </Row>
          <Row>
            <Col className="">
              Perfil asignado
            </Col>
            <Col className="">
              {data.profiles}
            </Col>
          </Row>
          <Row>
            <Col className="">
              Vigencia
            </Col>
            <Col className="">
              {data.vigency} días
            </Col>
          </Row>
          <Row>
            <Col className="">
              Días restantes
            </Col>
            <Col className="">
              {vigencyLabel}
            </Col>
          </Row>
          <Row className="">
            <Col className="">
              <div className={"align-center pt-5"}>
                {data.notes}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
}

const Index = observer(() => {
  const mainRef = useRef()
  const [data, setData] = useState()
  const params = useParams()
  const fetchData = async (number) => {
    const {code} = params
    const res = await appDataStore.post('/public-api/productByShortener', {
      code,
      number
    })
    let data = res.data
    if (res.result === 'error') return alert('No se encontró información')
    setData(data)
  }

  return <div className="plataforma-bg">
    <main ref={mainRef}>
      <Container>
        {data ? <AccountData data={data}/> : <CaptureData onSubmit={(number) => fetchData(number)}/>}
      </Container>
    </main>
  </div>

})

export default Index
