import React, {useEffect, useState} from "react"
import {Button, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap"
import {useAppDataStore} from "../../../../stores/appDataStore"
import {toast} from "../../../../components/Alert"
import AssocModal from "./AssocModal"

const EditModal = ({data, toggle, visible, onSave}) => {
  const [id, setId] = useState()
  const [amount, setAmount] = useState('')
  const [carrier, setCarrier] = useState('')
  const [incentive, setIncentive] = useState('')
  const [name, setName] = useState('')
  const [enabled, setEnabled] = useState(true)
  const [meta, setMeta] = useState(0)
  const [position, setPosition] = useState(0)
  const [categoryId, setCategoryId] = useState(0)
  const [productId, setProductId] = useState(0)
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [assocVisible, setAssocVisible] = useState(false)
  const appDataStore = useAppDataStore()

  const refreshData = async () => {
    let {packagesCategories} = await appDataStore.get('/backoffice/packagesCategories')
    setCategories(packagesCategories)

    let {products} = await appDataStore.get('/backoffice/products')
    setProducts(products.filter(product => product.ProductCategory.route === 'packages'))
  }
  useEffect(() => {
    refreshData()
  }, [])

  useEffect(() => {
    if (!data) {
      setId(null)
      setAmount('')
      setCarrier('')
      setIncentive('')
      setEnabled(true)
      setMeta("{}")
      setName('')
      setCategoryId('')
      setProductId('')
      setPosition(0)
      return
    }
    setId(data.id)
    setAmount(data.amount)
    setCarrier(data.carrier)
    setIncentive(data.incentive)
    setEnabled(data.enabled)
    setMeta(data.meta)
    setName(data.name)
    setCategoryId(data.categoryId)
    setProductId(data.productId)
    setPosition(data.position)
  }, [data])


  const onSaveEvent = async () => {
    try {
      let res = {}
      let post = {
        id,
        amount,
        carrier,
        incentive,
        enabled,
        meta,
        name,
        categoryId,
        productId,
        position,
      }
      if (!id) post = {packages: [post]}
      res = await appDataStore.post(`/backoffice/packages/${id ? 'edit' : 'add'}`, post)
      if (res.result === "error") return await toast(res.error)
      if (res.packages && res.packages[0].success === false) return await toast(res.packages[0].result)

      if (onSave) onSave()
      toggle()
    } catch (err) {
      await toast(err.message)
    }
  }

  const toggleAssoc = () => {
    setAssocVisible(!assocVisible)
  }
  return <div>
    {assocVisible ? <AssocModal pkg={data} visible={assocVisible} toggle={toggleAssoc}></AssocModal> : null}
    <Modal
      className="modal-dialog-centered"
      isOpen={visible}
      toggle={() => toggle()}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title"
            id="userModal">
          Paquete
        </h5>
        <button
          aria-label="Cerrar"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => toggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <label> Costo del paquete </label>
              <Input
                className="form-control-alternative"
                value={amount}
                placeholder="Costo del paquete"
                onChange={e => setAmount(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label> Nombre del paquete </label>
              <Input
                className="form-control-alternative"
                value={name}
                placeholder="nombre del paquete"
                onChange={e => setName(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label> Proveedor </label>
              <Input
                className="form-control-alternative"
                value={carrier}
                placeholder="Descripción"
                onChange={e => setCarrier(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label> Posición en lista </label>
              <Input
                value={position}
                className="form-control-alternative"
                placeholder="Posición en la lista"
                onChange={e => setPosition(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <label> Incentivo </label>
              <Input
                value={incentive}
                className="form-control-alternative"
                placeholder="Incentivo"
                onChange={e => setIncentive(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <label> Producto </label>
              <Input
                value={productId}
                className="form-control-alternative"
                type="select"
                on
                onChange={e => setProductId(e.target.value)}
              >
                <option value="">Seleccione una opción</option>
                {
                  products.map(product => {
                    return <option value={product.id}>{product.name}</option>
                  })
                }
              </Input>
            </FormGroup>

            <FormGroup>
              <label> Categoría </label>
              <Input
                value={categoryId}
                className="form-control-alternative"
                type="select"
                on
                onChange={e => setCategoryId(e.target.value)}
              >
                <option value="">Seleccione una opción</option>
                {
                  categories.map(category => {
                    return <option value={category.id}>{category.category}</option>
                  })
                }
              </Input>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  className="form-control-alternative"
                  checked={enabled}
                  type="checkbox"
                  onChange={e => {
                    setEnabled(!enabled)
                  }}
                />{' '}
                Habilitado
              </Label>
            </FormGroup>
          </Col>
          <Col lg={12}>
            <FormGroup>
              <Label for="exampleText">Configuración JSON</Label>
              <Input
                style={{height: 230}}
                value={meta}
                onChange={e => setMeta(e.target.value)}
                className="form-control-alternative"
                type="textarea"/>
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="success" type="button" size={'sm'} onClick={toggleAssoc}>
          Asociar paquete a proveedor
        </Button>
        <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          size={'sm'}
          onClick={toggle}
        >
          Cancelar
        </Button>
        <Button color="primary" type="button" size={'sm'} onClick={onSaveEvent}>
          Guardar
        </Button>
      </div>
    </Modal></div>
}
export default EditModal
