import React, {useEffect, useState} from "react"
import {Button, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap"
import {useAppDataStore} from "../../../../stores/appDataStore"
import images from "../../../../stores/images"
import {toast} from "../../../../components/Alert"
import {Buffer} from "buffer"

const EditModal = ({data, toggle, visible, onSave}) => {
  const [id, setId] = useState()
  const [name, setName] = useState('')
  const [image, setImage] = useState('')
  const [config, setConfig] = useState('')
  const [carrier, setCarrier] = useState('')
  const [enabled, setEnabled] = useState(true)
  const [tipType, setTipType] = useState('percent')
  const [position, setPosition] = useState(0)
  const [fixedPrice, setFixedPrice] = useState(0)
  const [categoryId, setCategoryId] = useState('')
  const [deferredTip, setDeferredTip] = useState(false)

  const [categories, setCategories] = useState([])

  const appDataStore = useAppDataStore()

  useEffect(() => {
    appDataStore
      .get('/backoffice/categories')
      .then(({productCategories}) => {
        setCategories(productCategories)
      })
  }, [])

  useEffect(() => {
    if (!data) {
      setId(null)
      setName('')
      setImage('')
      setConfig({})
      setCarrier('')
      setEnabled(true)
      setTipType('percent')
      setPosition(0)
      setFixedPrice(0)
      setCategoryId('')
      setDeferredTip(false)
      return
    }

    let config = data.config
    try {
      config = JSON.parse(data.config)
    } catch (err) {

    }
    setId(data.id)
    setName(data.name)
    setImage(data.imageUrl)
    setConfig(JSON.stringify(config, null, 4))
    setCarrier(data.carrier)
    setEnabled(data.enabled)
    setTipType(data.tipType)
    setPosition(data.position)
    setFixedPrice(data.fixedPrice)
    setCategoryId(data.categoryId)
    setDeferredTip(data.deferredTip)
  }, [data])

  const onFileSelected = (e) => {
    let [file] = e.target.files
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      debugger
      setImage(event.target.result)
    })
    reader.readAsDataURL(file)
  }

  const onSaveEvent = async () => {
    try {
      let res = {}

      let post = {
        id,
        name,
        image,
        config,
        carrier,
        enabled,
        tipType,
        position,
        fixedPrice,
        categoryId,
        deferredTip
      }
      if (!id) post = {products: [post]}
      res = await appDataStore.post(`/backoffice/products/${id ? 'edit' : 'add'}`, post)
      if (res.result === "error") return await toast(res.error)
      if (res.products && res.products[0].success === false) return await toast(res.products[0].result)

      if (onSave) onSave()
      toggle()
    } catch (err) {
      await toast(err.message)
    }
  }
  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Producto
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={toggle}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={6}>
          <FormGroup>
            <label> Nombre del producto </label>
            <Input
              className="form-control-alternative"
              value={name}
              placeholder="nombre del producto"
              onChange={e => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Tipo de comisión </label>
            <Input
              value={tipType}
              className="form-control-alternative"
              type="select"
              onChange={e => setTipType(e.target.value)}
            >
              <option value="percent">Porcentaje</option>
              <option value="sale">Comisión por venta</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <label> Precio Fijo inicial </label>
            <Input
              className="form-control-alternative"
              value={fixedPrice}
              placeholder="precio inicial"
              onChange={e => setFixedPrice(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Posición en listas </label>
            <Input
              className="form-control-alternative"
              value={position}
              placeholder="Posición en lista"
              onChange={e => setPosition(e.target.value)}
            />
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                className="form-control-alternative"
                checked={deferredTip}
                type="checkbox"
                onChange={() => setDeferredTip(!deferredTip)
                }
              />{' '}
              Comisión diferida
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                className="form-control-alternative"
                checked={enabled}
                type="checkbox"
                onChange={() => setEnabled(!enabled)
                }
              />{' '}
              Habilitado
            </Label>
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup>
            <label> ID del producto </label>
            <Input
              value={carrier}
              className="form-control-alternative"
              placeholder="carrier"
              onChange={e => setCarrier(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Categoría </label>
            <Input
              value={categoryId}
              className="form-control-alternative"
              type="select"
              on
              onChange={e => setCategoryId(e.target.value)}
            >
              <option value="">Seleccione una opción</option>
              {
                categories.map(category => {
                  return <option value={category.id}>{category.category} - {category.description}</option>
                })
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <label> Imagen del producto </label>
            <Input
              accept={".png,.jpg,.jpeg"}
              style={{color: "#8898aa"}}
              type={"file"}
              className="form-control-alternative"
              placeholder="carrier"
              onChange={onFileSelected}
            />
          </FormGroup>
          <div>
            <img alt={"logo"} src={image || images.default} width={130} height={130}/>
          </div>
        </Col>
        <Col lg={12}>
          <FormGroup>
            <Label for="exampleText">Configuración JSON</Label>
            <Input
              style={{height: 230}}
              value={config}
              onChange={e => setConfig(e.target.value)}
              className="form-control-alternative"
              type="textarea" name="text"/>
          </FormGroup>
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={toggle}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={onSaveEvent}>
        Guardar
      </Button>
    </div>
  </Modal>
}
export default EditModal
