import {action, observable, makeObservable, reaction, runInAction} from 'mobx'
import {createContext, useContext} from "react"
import Axios from 'axios'
import {Buffer} from "buffer"
import images from "./images"

let axios = Axios.create({timeout: 5 * 60 * 1000, baseURL: `/api/v1`})

class AppDataStore {
  customer = {}
  products = []
  categories = []
  topup = {carriers: [], categories: [], packages: []}
  childs = []

  async updateCustomer(customer) {

    if (!customer) {
      let res = await this.get('/public-api/info')
      customer = res.user
    }
    runInAction(() => {
      if (this.customer === null) return this.customer = customer
      Object.keys(customer).forEach(key => {
        if (this.customer[key] !== customer[key]) {
          this.customer[key] = customer[key]
        }
      })
    })
    return customer
  }

  async updateChilds(childs) {
    if (!childs) {
      let res = await this.get('/public-api/childs')
      childs = res.childs
    }
    runInAction(() => {
      this.childs = childs
    })
    return childs
  }

  async updatePackages(packages) {
    if (!packages) {
      let res = await this.get('/public-api/packages')
      packages = res.packages
      await this.updatePackagesCategories(res.categories)
    }
    runInAction(() => {
      this.topup.packages = packages
    })
    return packages
  }

  async updatePackagesCategories(categories) {
    if (!categories) {
      let res = await this.get('/public-api/packages')
      categories = res.categories
    }
    runInAction(() => {
      this.topup.categories = categories
    })
    return categories
  }

  async updateCarriers(carriers) {
    if (!carriers) {
      let res = await this.get('/public-api/carriers')
      carriers = res.carriers
    }
    runInAction(() => {
      this.topup.carriers = carriers
    })
    return carriers
  }

  async updateProducts(products) {
    if (!products) {
      let res = await this.get('/public-api/products')
      products = res.products
      await this.updateCategories(res.categories)
    }
    runInAction(() => {
      this.products = products
    })
    return products
  }

  async updateCategories(categories) {
    if (!categories) {
      let res = await this.get('/public-api/products')
      categories = res.categories
    }
    runInAction(() => {
      this.categories = categories
    })
    return categories
  }

  loadPersistentData() {
    const localCustomer = localStorage.getItem('customer')
    if (localCustomer && localCustomer !== 'undefined') this.customer = JSON.parse(localCustomer)

    const persistentCategories = localStorage.getItem('categories')
    if (persistentCategories && persistentCategories !== 'undefined') this.categories = JSON.parse(persistentCategories)


    const persistentProducts = localStorage.getItem('products')
    if (persistentProducts && persistentProducts !== 'undefined') this.products = JSON.parse(persistentProducts)


    const persistentTopup = localStorage.getItem('topup')
    if (persistentTopup && persistentTopup !== 'undefined') this.topup = JSON.parse(persistentTopup)
  }

  post = async (endpoint, post, config = {}) => {
    try {
      if (this.customer?.token) {
        if (!config.headers) config.headers = {}
        config.headers.authorization = `Token ${this.customer.token}`
      }
      const res = await axios.post(endpoint, {data: post}, config)
      const {data} = res
      return data
    } catch (error) {
      console.log(error)
      if (error.response.status === 401) await this.logout()
      throw error
    }
  }

  get = async (endpoint, config = {}) => {
    try {
      if (this.customer?.token) {
        if (!config.headers) config.headers = {}
        config.headers.authorization = `Token ${this.customer.token}`
      }
      const res = await axios.get(endpoint, config)
      const {data} = res
      return data
    } catch (error) {
      if (error.response.status === 401) await this.logout()
      throw error
    }
  }

  login = async (username, password) => {
    let data = {username, password}
    let res = await this.post('/auth/login', data)
    if (res.user) await this.updateCustomer(res.user)
    return res
  }

  setBalanceInUse = async (balanceInUse) => {
    let data = {balanceInUse}
    let res = await this.post('/public-api/setBalanceInUse', data)
    if (res.user) await this.updateCustomer(res.user)
    return res
  }

  logout = async () => {
    let res = await this.post('/auth/logout')
    this.customer = null
    return this.customer
  }

  getPurchaseReport = async (startDate, endDate, balanceId) => {
    return await this.post('/reports/accounting', {
      filter: {
        startDate,
        endDate,
        balanceId,
        type: "BUY"
      }
    })
  }

  getProfits = async (startDate, endDate) => {
    return await this.post('/reports/accounting', {
      filter: {
        startDate,
        endDate,
        type: "PROFIT",
      }
    })
  }

  getTransfersReport = async (startDate, endDate, user, balanceId) => {
    return await this.post('/reports/accounting', {
      filter: {
        startDate,
        endDate,
        balanceId,
        username: user,
        type: "DISTRIBUTION",
      }
    })
  }

  getSalesReport = async (startDate, endDate, status, category, product, number) => {
    return await this.post('/reports/transactions', {
      filter: {
        startDate,
        endDate,
        status,
        category,
        product,
        number
      }
    })
  }

  getAdminSalesReport = async (startDate, endDate, status, category, product, number) => {
    return await this.post('/reports/transactions', {
      filter: {
        rol: 'admin',
        startDate,
        endDate,
        status,
        category,
        product,
        number
      }
    })
  }

  getConsignmentsReport = async (startDate, endDate, amount, bank, account, reference, consignmentType, status) => {
    debugger
    return await this.post('/reports/consignments', {
      filter: {
        startDate,
        endDate,
        amount,
        bank,
        account,
        reference,
        consignmentType,
        status
      }
    })
  }

  getChildProducts = async (userId) => {
    let res = await this.post('/public-api/products', {userId})
    const {products, categories} = res

    return {products, categories}
  }

  saveChildProducts = async (userId, products, force = false) => {
    return await this.post('/public-api/products/set', {userId, products, force})
  }

  editChild = async (userId, username, name, phone, email, docType, docNumber) => {
    return await this.post('/public-api/editChild', {
      userId,
      username,
      name,
      phone,
      email,
      docType,
      docNumber
    })
  }

  createChild = async (username, password, rol, name, phone, email, docType, docNumber) => {
    return await this.post('/public-api/createChild', {
      username,
      name,
      password,
      rol,
      phone,
      email,
      docType,
      docNumber
    })
  }

  createChildByReference = async (resellerId, username, password, name, phone, email, docType, docNumber) => {
    return await this.post('/public-api/createChildByReference', {
      resellerId,
      username,
      name,
      password,
      phone,
      email,
      docType,
      docNumber
    })
  }

  transferBalance = async (userId, amount, reverse, balanceType, notes) => {
    return await this.post('/public-api/transferBalance', {
      userId,
      amount,
      reverse,
      balanceType,
      notes
    })
  }

  updateAuthStatus = async (userId, blocked) => {
    return await this.post('/public-api/setChildAuthStatus', {
      userId,
      blocked
    })
  }

  deleteChild = async (userId) => {
    return await this.post('/public-api/deleteChild', {
      userId
    })
  }

  resetPassword = async (userId, blocked) => {
    return await this.post('/public-api/resetPassword', {
      userId
    })
  }

  getDigitalAccounts = async () => {
    return await this.get('/backoffice/digitalAccounts')
  }

  getResellerByCode = async (code) => {
    return await this.post('/public-api/getResellerByCode', {code})
  }

  getActivity = async () => {
    return await this.get('/public-api/getActivity')
  }

  getSessions = async () => {
    return await this.get('/public-api/getSessions')
  }

  closeSession = async (sessionId) => {
    return await this.post('/public-api/closeSession', {sessionId})
  }

  getSupports = async () => {
    return await this.get('/public-api/getSupports')
  }

  createSupport = async (text, transactionId) => {
    return await this.post('/public-api/createSupport', {text, transactionId})
  }

  updateSupport = async (text, supportId, attachment) => {
    return await this.post('/public-api/updateSupport', {text, supportId, attachment})
  }

  async getProviders() {
    return await this.get('/backoffice/providers')
  }

  async editProvider(id, data) {
    return await this.post('/backoffice/providers/edit', {id, ...data})

  }

  constructor() {
    makeObservable(this, {
      customer: observable,
      topup: observable,
      childs: observable,
      products: observable,
      categories: observable,
      loadPersistentData: action,
      updateCarriers: action,
      updateCustomer: action,
      updatePackages: action,
      updateProducts: action,
      updateChilds: action
    })
    this.loadPersistentData()
    if (this.customer) {
      this.updateProducts().then()
      setInterval(async () => {
        await this.updateCustomer()
      }, 1000 * 10)
    }
  }
}

export const appDataStore = new AppDataStore()
export const AppDataContext = createContext(null)
export const useAppDataStore = () => useContext(AppDataContext)
export const AppDataProvider = ({children}) =>
  <AppDataContext.Provider
    value={appDataStore}>{children}
  </AppDataContext.Provider>

export default appDataStore

reaction(() => JSON.stringify(appDataStore.customer), (varStr) => {
    localStorage.setItem('customer', varStr)
  }
)

reaction(() => JSON.stringify(appDataStore.topup), (varStr) => {
    localStorage.setItem('topup', varStr)
  }
)

reaction(() => JSON.stringify(appDataStore.products), (varStr) => {
    localStorage.setItem('products', varStr)
  }
)

reaction(() => JSON.stringify(appDataStore.categories), (varStr) => {
    localStorage.setItem('categories', varStr)
  }
)

reaction(() => JSON.stringify(appDataStore.topup), (varStr) => {
    localStorage.setItem('topup', varStr)
  }
)
