import React, {useEffect, useRef, useState} from "react"
import CustomerNavbar from "../../../../components/Navbars/CustomerNavbar"
import Shape from "../../../../components/Shape"
import {Button, Col, Container, Row} from "reactstrap"
import SimpleFooter from "../../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from '../../../../components/Table'
import {confirm, toast} from '../../../../components/Alert'
import {useAppDataStore} from "../../../../stores/appDataStore"
import AccountModal from "./AccountModal"
import MassiveModal from "./MassiveModal"

const DigitalAccounts = observer(() => {

  const [selectedData, setSelectedData] = useState({})
  const [selectedRows, setSelectedRows] = useState([])
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [massiveModalVisible, setMassiveModalVisible] = useState(false)
  const [data, setData] = useState([])
  const appDataStore = useAppDataStore()
  const mainRef = useRef()

  useEffect(() => {
    refreshData()
  }, [])

  const columns = [
    {
      checkboxSelection: true,
      field: 'id',
      flex: 1,
      maxWidth: 80,
      headerName: '',
    },
    {
      field: 'Product.imageUrl',
      flex: 1,
      maxWidth: 80,
      headerName: '',
      cellRenderer: Formatters.base64image
    },
    {
      field: 'id',
      maxWidth: 90,
      headerName: 'ID',

    },
    {
      field: 'carrier',
      flex: 1,
      headerName: 'Tipo',
    },
    {
      field: 'user',
      flex: 1,
      headerName: 'Usuario',
      minWidth: 190,
    },
    {
      field: 'password',
      maxWidth: 150,
      headerName: 'contraseña',
    },
    {
      field: 'profiles',
      headerName: 'perfiles',
      maxWidth: 150,
      flex: 1,
    },
    {
      field: 'canRenew',
      headerName: 'Renovable?',
      maxWidth: 150,
      flex: 1,
      cellRenderer: Formatters.boolean
    },
    {
      field: 'createdAt',
      headerName: 'Fecha cración',
      valueFormatter: Formatters.datetime
    },
    {
      field: '',
      flex: 1,
      maxWidth: 150,
      headerName: 'Opciones',
      cellRenderer: ({data}) => {
        return <div className="action-icons">
          <Button size={'sm'}
                  onClick={()=>{
                    setSelectedData(data)
                    toggleAccountModal()
                  }}
          >
            <i className="fa fa-pencil p-0"/>
          </Button>

        </div>
      }
    }
  ]

  const buttons = [{
    label: 'Subir cuenta digital',
    icon: 'ni-circle-08',
    onClick: () => {
      setSelectedData({})
      toggleAccountModal()
    }
  }, {
    label: 'Subir cuentas masivo',
    icon: 'ni-circle-08',
    onClick: () => {
      toggleMassiveModal()
    }
  },
    {
      label: 'Eliminar',
      icon: 'ni-circle-08',
      onClick: async () => {
        let modalRes = await confirm(`se eliminaran <b>${selectedRows.length}</b> cuentas. esta opción no se puede deshacer. desea continuar?`)
        if (modalRes) {
          const id = selectedRows.reduce((previusValue, currentValue) => {
            previusValue.push(currentValue.id)
            return previusValue
          }, [])
          let res = await appDataStore.post('/backoffice/digitalAccounts/delete', {id})
          if (res.result === 'error') return await toast(res.error)
          await refreshData()
        }
      }
    }
  ]

  const rowStyle = ({data}) => {
    let style = {color: 'rgb(0,0,0)'}
    if (data.sold) style = {color: 'rgb(34,151,54)', fontStyle: 'italic'}
    if (data.warranty) style = {color: 'rgb(203,25,25)', fontStyle: 'italic'}

    return style
  }


  const refreshData = async () => {
    try {
      let {digitalAccounts} = await appDataStore.getDigitalAccounts()
      setData(digitalAccounts)
    } catch (err) {
      console.log(err)
    }
  }

  const toggleAccountModal = () => {
    setAccountModalVisible(!accountModalVisible)
  }

  const toggleMassiveModal = () => {
    setMassiveModalVisible(!massiveModalVisible)
  }

  const onSelectionChanged = ({api}) => {
    const selectedRows = api.getSelectedRows()
    setSelectedRows(selectedRows)
  }

  return (
    <>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <Row>
              <Col lg={12} md={12}>
                <AccountModal
                  data={selectedData}
                  visible={accountModalVisible}
                  toggle={toggleAccountModal}
                  onSave={refreshData}
                />
                <MassiveModal
                  onSave={refreshData}
                  visible={massiveModalVisible}
                  toggle={toggleMassiveModal}
                />
                <Table
                  tableName={'Cuentas Digitales'}
                  cols={columns}
                  rows={data}
                  buttons={buttons}
                  rowStyle={rowStyle}
                  rowSelection={'multiple'}
                  onSelectionChanged={onSelectionChanged}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </>
  )
})

export default DigitalAccounts
