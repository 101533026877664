import React, {useEffect, useState} from "react";
import {
  Button,
  Col,
  Input,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import {useAppDataStore} from "../../../../stores/appDataStore";
import classnames from "classnames";
import {confirm, toast, alert} from "../../../../components/Alert"
import images from "../../../../stores/images";

const ProductAssocModal = ({user, toggle, visible, onSave}) => {

  const appDataStore = useAppDataStore()
  const [selectedCategory, setSelectedCategory] = useState(null)

  const [productsData, setProductsData] = useState([])
  useEffect(() => {
    fetchData()
  }, [user, visible])

  const fetchData = async () => {
    await appDataStore.updateProducts()
    const childProducts = await appDataStore.getChildProducts(user.id)

    const productData = appDataStore.products
    for (let product of productData) {
      const pc = childProducts.products.find(p => product.id === p.id)
      product.childEnabled = false
      product.childProfit = 0

      if (pc) {
        product.childEnabled = pc.enabled
        product.childProfit = pc.percent
      }
    }
    setProductsData(productData)
  }
  const toggleNavs = (e, category) => {
    setSelectedCategory(category)
    const check = document.getElementById('massive-toggle-check')
    check.checked = false
  }

  const massiveToggle = (e) => {
    const checks = document.getElementsByClassName("profits-enable-cat-" + selectedCategory.id)
    for (let i = 0; i < checks.length; i++) {
      const check = checks[i]
      check.checked = e.target.checked
    }
  }
  const save = async (force) => {
    const data = []
    for (let product of productsData) {
      const enabled = document.getElementById('profit-enable-' + product.id)
      const profits = document.getElementById('profit-' + product.id)
      product.childProfit = parseFloat(profits.value)
      product.childEnabled = enabled.checked
      let color = '#8898aa'

      if (product.childProfit > product.percent) {
        color = '#FF0000'
        profits.style.borderColor = profits.style.color = profits.style.borderBottomColor = profits.style.borderLeftColor = profits.style.borderRightColor = profits.style.borderTopColor = color
        profits.focus()
        return
      }
      profits.style.borderColor = profits.style.color = profits.style.borderBottomColor = profits.style.borderLeftColor = profits.style.borderRightColor = profits.style.borderTopColor = color
      data.push({id: product.id, enabled: product.childEnabled, profit: product.childProfit})
    }
    const res = await appDataStore.saveChildProducts(user.id, data)
    if (res.result === 'error') {

      if (res.error === '#force') {
        const r = await confirm('Un cliente de este distribuidor tiene mayor porcentaje en un producto, desea normalizar la jerarquía?')
        if (r.value) {
          const res = await appDataStore.saveChildProducts(user.id, data, true)
          if (res.result === 'error') return toast(res.error)
          return alert(res.message)
        }
      }

      return toast(res.error)
    }
    toggle()
  }

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="xl"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Asociar productos a {user.username}
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={12}>
          <div className="nav-wrapper">
            <Nav
              className="nav-fill flex-column flex-md-row"
              id="tabs-text"
              pills
              role="tablist"
            >
              {appDataStore.categories.map(category => {
                return (
                  <NavItem key={category.id}>
                    <NavLink
                      aria-selected={selectedCategory && selectedCategory.id === category.id}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: selectedCategory && selectedCategory.id === category.id
                      })}
                      onClick={e => toggleNavs(e, category)}
                      href="#pablo"
                      role="tab"
                    >
                      {category.category}
                    </NavLink>
                  </NavItem>)
              })
              }
            </Nav>
          </div>
        </Col>
      </Row>
      <Row style={{color: '#32325d'}}>
        <Col sm={1} lg={1}>
          <label style={{marginTop: 6}} className="custom-toggle">
            <input id={'massive-toggle-check'} defaultChecked={false} type="checkbox" onChange={massiveToggle}/>
            <span className="custom-toggle-slider rounded-circle"/>
          </label>
        </Col>
        <Col xs={1} sm={1} lg={1}></Col>
        <Col xs={3} sm={3} lg={3}>Producto</Col>
        <Col xs={2} sm={2} lg={2}>Mi comision</Col>
        <Col xs={2} sm={2} lg={2}>Comision</Col>
        <Col className={"d-none d-lg-block"} sm={3} lg={3}>Tipo</Col>
      </Row>
      {
        productsData.map(product => {
          return (
            <Row
              className={"border-bottom"}
              style={{
                paddingTop: 3,
                paddingBottom: 3,
                color: '#32325d',
                display: (selectedCategory && product.categoryId === selectedCategory.id ? '' : 'none')
              }}>
              <Col xs={3} sm={1} lg={1}>
                <label style={{marginTop: 6}} className="custom-toggle">
                  <input id={'profit-enable-' + product.id}
                         className={'profits-enable profits-enable-cat-' + product.categoryId}
                         defaultChecked={product.childEnabled} type="checkbox"/>
                  <span className="custom-toggle-slider rounded-circle"/>
                </label>
              </Col>
              <Col xs={3} sm={1} lg={1}>
                <img alt={product.name} width={40} src={product.imageUrl  || images.default}></img>
              </Col>
              <Col xs={6} sm={3} lg={3}>
                {product.name}
              </Col>
              <Col xs={6} sm={2} lg={2}>
                {product.percent}
              </Col>
              <Col xs={12} sm={2} lg={2}>
                <Input
                  inl
                  id={'profit-' + product.id}
                  size={'sm'}
                  name="username"
                  className="form-control-alternative profits"
                  placeholder=""
                  defaultValue={product.childProfit}
                />
              </Col>
              <Col className={"d-none d-lg-block"} sm={3}
                   lg={3}>{product.tipType === 'percent' ? 'porcentaje' : 'pesos'}</Col>
            </Row>
          )
        })
      }

    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={() => toggle()}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={save}>
        Guardar
      </Button>
    </div>
  </Modal>
}
export default ProductAssocModal