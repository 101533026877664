import React, {useEffect, useMemo, useRef, useState} from "react"
import {Button, Col, Modal, Row} from "reactstrap"
import {useAppDataStore} from "../../../../stores/appDataStore"
import {toast} from "../../../../components/Alert"
import {AgGridReact} from "ag-grid-react"
import {AG_GRID_LOCALE_ES, Formatters} from "../../../../components/Table"

const PackagesAssocModal = ({provider, toggle, visible, onSave}) => {
  const [dataSource, setDataSource] = useState([])
  const [packagesAssoc, setPackagesAssoc] = useState([])
  const gridRef = useRef()

  const appDataStore = useAppDataStore()
  const containerStyle = {marginTop: '10px', borderRadius: '0.75rem', padding: 3, backgroundColor: '#FFFFFF'}

  const refreshData = async () => {
    const {packagesAssoc} = await appDataStore.get(`/backoffice/providers/assoc?provider=${provider.providerName}`)
    setDataSource(packagesAssoc)
    setPackagesAssoc(packagesAssoc)
  }

  useEffect(() => {
    refreshData()
  }, [provider])

  const columns = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Paquete',
      minWidth: 380,
    },
    {
      field: 'carrier',
      flex: 1,
      headerName: 'Operador',
    },
    {
      field: 'amount',
      flex: 1,
      headerName: 'Valor',
      editable: true,
      valueFormatter: Formatters.cash
    },
    {
      field: 'providerId',
      flex: 1,
      headerName: 'ID proveedor',
      editable: true,
    },
    {
      field: 'updatedAt',
      flex: 1,
      headerName: 'Ultima modificación',
      valueFormatter: Formatters.datetime
    }
  ]

  const innerDefaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true
  }))

  const onEdit = async ({oldValue, newValue, data}) => {
    if (oldValue === newValue) return
    const res = await appDataStore.post('/backoffice/providers/assoc/set', {
      provider: provider.providerName,
      providerId: newValue,
      pkg: data
    })
    await refreshData()
  }

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="xl"
  >
    <div className="modal-header">
      <h5 className="modal-title"
          id="userModal">
        Asociar paquetes al proveedor <b>{provider.providerName}</b>
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col>
          <div style={containerStyle}>
            <div className="ag-theme-material" style={{width: '100%', height: 500}}>
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                enableCellTextSelection={false}
                columnDefs={columns} // Column Defs for Columns
                defaultColDef={innerDefaultColDef} // Default Column Properties
                localeText={AG_GRID_LOCALE_ES}
                rowData={dataSource} // Row Data for Rows
                onCellEditingStopped={onEdit}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="primary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={toggle}
      >
        Cerrar
      </Button>
    </div>
  </Modal>
}
export default PackagesAssocModal
