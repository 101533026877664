import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap";
import {useAppDataStore} from "../../../stores/appDataStore";
import images from "../../../stores/images";
import {toast} from "../../../components/Alert";
import {Buffer} from "buffer"

const ReportConsignmentModal = ({data, toggle, visible, onSave}) => {

  const [amount, setAmount] = useState('')
  const [account, setAccount] = useState('')
  const [bank, setBank] = useState('')
  const [selectedBank, setSelectedBank] = useState(1)
  const [consignmentType, setConsignmentType] = useState('CORRESPONSAL')
  const [voucher, setVoucher] = useState()
  const [notes, setNotes] = useState()
  const [reference, setReference] = useState()
  const appDataStore = useAppDataStore()

  const banks = [
    {bank: 'Bancolombia', account: '06946469554', id: 1, type: 'ahorros'}
  ]

  useEffect(() => {
    const b = banks.find(bank => bank.id === selectedBank)
    setBank(b.bank)
    setAccount(b.account)
  }, [selectedBank])

  const onFileSelected = (e) => {
    let [file] = e.target.files
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      setVoucher(event.target.result)
    })
    reader.readAsDataURL(file)
  }

  const onSaveEvent = async () => {
    try {
      const [voucherHeader, voucherBuff] = voucher.split(',')
      let res = {}
      let post = {
        amount,
        bank,
        account,
        reference,
        notes,
        consignmentType,
        voucher: voucherBuff,
        voucherHeader,
      }

      res = await appDataStore.post(`/public-api/reportConsignment/`, post)
      if (res.result === "error") return await toast(res.error)
      if (onSave) onSave()
      toggle()
    } catch (err) {
      await toast(err.message)
    }
  }

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Reportar consignación
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={6}>
          <FormGroup>
            <label> Valor </label>
            <Input
              className="form-control-alternative"
              value={amount}
              placeholder="valor de la consignación"
              onChange={e => setAmount(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Cuenta / Banco </label>
            <Input
              value={selectedBank}
              className="form-control-alternative"
              type="select"
              onChange={e => setSelectedBank(e.target.value)}
            >
              {banks.map(bank => <option key={bank.id}
                                         value={bank.id}>
                {bank.type} {bank.bank} / {bank.account}
              </option>)
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <label> Tipo de consignación </label>
            <Input
              value={consignmentType}
              className="form-control-alternative"
              type="select"
              onChange={e => setConsignmentType(e.target.value)}
            >
              <option value="CORRESPONSAL">Corresponsal bancario</option>
              <option value="TRANSFERENCIA">Transferencia Electrónica</option>
              <option value="CONSIGNACION">Consignación en banco</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <label> Referencia / Comprobante / Aprobación</label>
            <Input
              className="form-control-alternative"
              value={reference}
              placeholder="Referencia transacción"
              onChange={e => setReference(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <label> Voucher de consignación </label>
            <Input
              accept={".png,.jpg,.jpeg"}
              style={{color: "#8898aa"}}
              type={"file"}
              className="form-control-alternative"
              placeholder="carrier"
              onChange={onFileSelected}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <div>
            <img alt={"logo"} src={voucher || images.default} width={200}/>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <FormGroup>
            <label> Notas </label>
            <Input
              type="textarea"
              className="form-control-alternative"
              value={notes}
              placeholder="notas"
              onChange={e => setNotes(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={toggle}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={onSaveEvent}>
        Guardar
      </Button>
    </div>
  </Modal>
}
export default ReportConsignmentModal

