import React, {useRef} from "react"
import CustomerNavbar from "../../components/Navbars/CustomerNavbar"
import Shape from "../../components/Shape"
import {Col, Container, Row} from "reactstrap"
import SimpleFooter from "../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import {useAppDataStore} from "../../stores/appDataStore"
import {Link} from "react-router-dom"
import styles from './styles.css'
import images from "../../stores/images";

const RenderProducts = observer(() => {
  const appDataStore = useAppDataStore()
  const {categories} = appDataStore

  return categories.map(category => {
    return (
      <Link to={`/customer/products/${category.route}`}>
        <div className={'spanBox'}>
          <div className={'insideBox'}>
            <img
            className={'product-logo'}
              src={category.imageUrl  || images.default}
                 alt={category.description}/>
            {category.category}
          </div>
        </div>
      </Link>
    )
  })
})

const Index = observer(() => {
  const mainRef = useRef()

  return (
    <div>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md d-flex">
            <div className="col px-0">
              <Row>
                <Col lg="12 text-center">
                  <RenderProducts/>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Index
