import React from "react"

function Shape() {
  return (<div className="shape shape-style-1 bg-gradient-default">
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
    <span/>
  </div>)
}
export default Shape
