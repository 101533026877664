import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap";
import {useAppDataStore} from "../../../../stores/appDataStore";
import {toast} from "../../../../components/Alert";

const EditModal = ({data, toggle, visible, onSave}) => {
  const [id, setId] = useState()
  const [category, setCategory] = useState('')
  const [enabled, setEnabled] = useState(true)

  const appDataStore = useAppDataStore()

  useEffect(() => {
    if (!data) {
      setId(null)
      setCategory('')
      setEnabled(true)
      return
    }
    setId(data.id)
    setCategory(data.category)
    setEnabled(data.enabled)
  }, [data])


  const onSaveEvent = async () => {
    try {
      let res = {}
      let post = {
        id,
        category,
        enabled
      }
      if (!id) post = {packagesCategories: [post]}
      res = await appDataStore.post(`/backoffice/packagesCategories/${id ? 'edit' : 'add'}`, post)
      if (res.result === "error") return await toast(res.error)
      if (res.packagesCategories && res.packagesCategories[0].success === false) return await toast(res.packagesCategories[0].result)

      if (onSave) onSave()
      toggle()
    } catch (err) {
      await toast(err.message)
    }
  }
  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title"
          id="userModal">
        Categoria de paquete
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={6}>
          <FormGroup>
            <label> Nombre de la categoria </label>
            <Input
              className="form-control-alternative"
              value={category}
              placeholder="Nombre de la categoría"
              onChange={e => setCategory(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <FormGroup check>
            <Label check>
              <Input
                className="form-control-alternative"
                checked={enabled}
                type="checkbox"
                onChange={e => {
                  setEnabled(!enabled)
                }}
              />{' '}
              Habilitado
            </Label>
          </FormGroup>
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={toggle}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={onSaveEvent}>
        Guardar
      </Button>
    </div>
  </Modal>
}
export default EditModal