import React, {useEffect, useRef, useState} from "react"
import CustomerNavbar from "../../../components/Navbars/CustomerNavbar"
import Shape from "../../../components/Shape"
import {Button, Col, Container, FormGroup, Input, Modal, ModalHeader, Row,} from "reactstrap"
import SimpleFooter from "../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from '../../../components/Table'
import ReactDatetime from "react-datetime"
import moment from "moment"
import {useAppDataStore} from "../../../stores/appDataStore"
import "react-datetime/css/react-datetime.css";
import 'moment/locale/es';

const FilterBlock = ({onGenerate, appDataStore}) => {

  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment())
  const [status, setStatus] = useState('')
  const [category, setCategory] = useState('')
  const [product, setProduct] = useState('')
  const [number, setNumber] = useState('')
  const [products, setProducts] = useState([])

  const generate = () => {
    onGenerate(startDate, endDate, status, category, product, number)
  }
  return (
    <Row>
      <Col lg={12}>
        <div className={'search-box'}>
          Reporte de ventas
          <Row>
            <Col lg={6}>
              <label>Fecha inicial</label>
              <ReactDatetime
                locale={'es'}
                onChange={(date => setStartDate(date))}
                dateFormat={'YYYY-MM-DD'}
                initialValue={startDate.format('YYYY-MM-DD')}
                inputProps={{className: 'form-control form-control-sm'}}
                timeFormat={false}
                closeOnSelect={true}
              />
            </Col>
            <Col lg={6}>
              <label>Fecha Final</label>
              <ReactDatetime
                locale={'es'}
                onChange={(date => setEndDate(date))}
                dateFormat={'YYYY-MM-DD'}
                initialValue={endDate.format('YYYY-MM-DD')}
                inputProps={{className: 'form-control form-control-sm'}}
                timeFormat={false}
                closeOnSelect={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <label>Estado</label>
              <Input
                size="sm"
                id="exampleSelect"
                name="select"
                type="select"
                onChange={e => setStatus(e.target.value)}
              >
                <option value="">Todas</option>
                <option value="success">Exitosa</option>
                <option value="error">Denegada</option>
              </Input>
            </Col>
            <Col lg={6}>
              <label>Categoria</label>
              <Input
                size="sm"
                id="exampleSelect"
                name="select"
                type="select"
                onChange={e => {
                  setCategory(e.target.value)
                  setProducts(appDataStore.products.filter(product => product.categoryId == e.target.value))
                }}
              >
                <option value="">Todos</option>
                {
                  appDataStore.categories.map(category => {
                    return <option key={category.id} value={category.id}>{category.category}</option>
                  })
                }
              </Input>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <label>Número</label>
              <Input
                size="sm"
                type="text"
                onChange={e => setNumber(e.target.value)}/>
            </Col>
            <Col lg={6}>
              <label>Producto</label>
              <Input
                size={'sm'}
                id="exampleSelect"
                name="select"
                type="select"
                onChange={e => setProduct(e.target.value)}
              >
                <option value="">Todos</option>
                {
                  products.map(product => {
                    return <option key={product.id} value={product.id}>{product.name}</option>
                  })
                }
              </Input>
            </Col>
          </Row>
          <p></p>
          <Button size={'sm'} onClick={generate}>Generar reporte</Button>
        </div>
      </Col>
    </Row>)
}

const Sales = observer(() => {

  const appDataStore = useAppDataStore()

  const [data, setData] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const mainRef = useRef()
  const [selectedRow, setSelectedRow] = useState()
  const columns = [
    {
      field: 'id',
      maxWidth: 60,
      flex: 1,
      headerName: 'ID'
    },
    {
      field: 'amount',
      flex: 1,
      headerName: 'Valor',
      valueFormatter: Formatters.cash
    },
    {
      field: 'number',
      flex: 1,
      headerName: 'Número'
    },
    {
      field: 'description',
      flex: 1,
      headerName: 'Descripción',
      cellRenderer: ({data, value}) => {
        return <a href={'#'} onClick={() => launchDescModal(data)}>{value}</a>
      }
    },
    {
      field: 'status',
      maxWidth: 150,
      headerName: 'Estado',
      valueFormatter: Formatters.status
    },
    {
      field: 'carrier',
      headerName: 'Operador',
      flex: 1,
    },
    {
      field: 'transactionId',
      headerName: 'ID transacción',
      flex: 1,
    },
    {
      field: 'createdAt',
      maxWidth: 150,
      headerName: 'fecha',
      valueFormatter: Formatters.datetime
    }
  ]

  const launchDescModal = (data) => {
    setSelectedRow(data)
    toggle()
  }
  const toggle = () => {
    setIsModalVisible(!isModalVisible)
  }

  const generateReport = async (startDate, endDate, status, category, product, number) => {
    let res = await appDataStore.getSalesReport(startDate, endDate, status, category, product, number)
    if (res.transactions) setData(res.transactions)
  }

  return (
    <div>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <FilterBlock onGenerate={generateReport} appDataStore={appDataStore}/>
            {selectedRow ?
              <Modal
                className="modal-dialog-centered"
                isOpen={isModalVisible}
                toggle={() => toggle()}
                size="lg"
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="userModal">
                    Transacción {selectedRow.transactionId} - {selectedRow.number}
                  </h5>
                  <button
                    aria-label="Cerrar"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggle()}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <FormGroup>
                      <label> {selectedRow.providerResponse} </label>
                    </FormGroup>
                                      </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    size={'sm'}
                    onClick={() => toggle()}
                  >
                    Cerrar
                  </Button>
                </div>
              </Modal> : null}
            <Row>
              <Col lg={12} md={12}>
                <Table cols={columns}
                       tableName={'Reporte de ventas'}
                       rows={data}/>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Sales
