import React, {useEffect, useRef, useState} from "react"
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Label, Card, CardBody
} from "reactstrap"

// core components
import CustomerNavbar from "../../components/Navbars/CustomerNavbar"
import SimpleFooter from "components/Footers/SimpleFooter.js"
import {observer} from "mobx-react"
import CarrierLogo from 'components/CarrierLogo'
import {Alert, toast} from 'components/Alert'
import Shape from "../../components/Shape"
import Spinner from "reactstrap/es/Spinner"
import {useAppDataStore} from "../../stores/appDataStore"

const InputBlock = observer(({numberInputRef, onChange, disabled, onSubmit}) => {
  const [number, setNumber] = useState('')

  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      if (onChange) onChange(number)
      if (onSubmit) onSubmit()
    }
  }

  return <Input placeholder={'Número'} value={number}
                innerRef={numberInputRef}
                onKeyUp={e => handleOnKeyUp(e)}
                disabled={disabled}
                onChange={e => setNumber(e.target.value)}
                onBlur={e => onChange ? onChange(number) : null}
  />
})

const PackagesBlock = observer(({packages, selectedCarrier, onDone}) => {
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [busy, setBusy] = useState(false)
  const [number, setNumber] = useState('')
  const appDataStore = useAppDataStore()

  const numberInputRef = useRef()

  const selectPackage = (selectedPackage) => {
    setSelectedPackage(selectedPackage)
    numberInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    numberInputRef.current.focus()
  }

  const run = async () => {

    if (!selectedCarrier) return await toast('Debe seleccionar el operador')
    if (!number.validateMin()) return await toast('El número ingresado es inválido')

    let res = await Alert.fire({
      title: <CarrierLogo carrier={selectedCarrier}/>,
      html: <>Numero: <b>{number}</b><br/>Paquete: <b>{selectedPackage.name}</b><br/>Valor: <b>{selectedPackage.amount.cash()}</b></>,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Recargar',
      cancelButtonText: 'Cancelar',
    })

    if (res && res.value) {
      setBusy(true)

      let res = await appDataStore.post('/public-api/topup', {
        number,
        productId: selectedCarrier.id,
        packageId: selectedPackage.id
      })
      setBusy(false)


      if (res.error) return toast(res.error)
      appDataStore.updateCustomer().then()
      await Alert.fire({
        position: 'center',
        icon: 'success',
        title: 'La recarga se ha realizado con éxito',
        showConfirmButton: true,
        timer: 3500
      })
      setNumber('')
      setSelectedPackage(null)
      if (onDone) onDone()
    }
  }


  return (
    <div>
      <Row className={'text-center p-10'}>
        <Container>
          <Row className="">
            <Col lg="12">
              <Row className="row-grid">
                {packages.map(carrierPackage => {
                  let selected = selectedPackage?.id === carrierPackage.id
                  return (
                    <Col key={carrierPackage.id} lg="12" sm="12" xs="12" className="m-1"
                         onClick={() => selectPackage(carrierPackage)}>
                      <Card className={"shadow border-5 " + (selected ? "alert-primary alert" : "")}>
                        <CardBody className="py-2 text-left">
                          <Row style={{fontSize: '0.8rem', color: selected ? '#FFFFFF' : '#000000'}}>
                            <Col lg={10}>
                              <CarrierLogo style={{maxWidth: 40}}
                                           carrier={selectedCarrier}/> {carrierPackage.name}
                            </Col>
                            <Col lg={2}>
                              {carrierPackage.amount.cash()}
                              {selected ? <i className="ni ni-2x ni-check-bold"/> : ''}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })
                }
              </Row>
            </Col>
          </Row>
        </Container>

      </Row>
      <Row className="justify-content-center text-center">
        <Col lg={3} sm={8} xs={8} className="align-items-center topup-col-centered">
          <Label>Número</Label>

          <InputBlock numberInputRef={numberInputRef} disabled={busy} onChange={number => setNumber(number)}
                      onSubmit={() => run()}/>
          <br/>
          <Button className={'primary'} onClick={() => run()} disabled={busy}>
            {busy ? <Spinner
              as="span"
              type="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            /> : null}
            &nbsp;Recargar
          </Button> </Col>
      </Row>
    </div>
  )
})

const Packages = observer(() => {

  const appDataStore = useAppDataStore()
  const [selectedCarrier, setSelectedCarrier] = useState()
  const [carriers, setCarriers] = useState([])
  const [categories, setCategories] = useState([])
  const [packages, setPackages] = useState([])

  const mainRef = useRef()
  const packageSelectorRef = useRef()

  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
    mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    let carriers = appDataStore.products.filter(product => product.route === 'packages')
    appDataStore.updatePackages()
    setCarriers(carriers)
  }, [])

  const selectCarrier = (selectedCarrier) => {
    let packages = appDataStore.topup.packages.filter(pkg => selectedCarrier.id === pkg.productId)
    let categories = appDataStore.topup.categories
    let carrierCategories = {}

    for (let pkg of packages) {
      carrierCategories[pkg.categoryId] = categories.find(category => category.id === pkg.categoryId)
    }
    setSelectedCarrier(selectedCarrier)
    setCategories(Object.values(carrierCategories))
    setPackages([])
    packageSelectorRef.current.focus()
    packageSelectorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const selectCategory = (category) => {
    let packages = appDataStore.topup.packages.filter(pkg => selectedCarrier.id === pkg.productId)
    packages = packages.filter(pkg => pkg.categoryId === category.id)
    setPackages(packages)
  }

  const cleanForm = () => {
    setPackages([])
    setCategories([])
    setSelectedCarrier(null)
    mainRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md d-flex">
            <div className="col px-0">
              <Row>
                <Col lg="12 text-center">
                  {
                    carriers.map(carrier => {
                      let selected = selectedCarrier && selectedCarrier.carrier === carrier.carrier
                      if (carrier.enabled)
                        return (
                          <CarrierLogo
                            key={carrier.id}
                            carrier={carrier}
                            selected={selected}
                            style={selected ? {border: '#FFFFFF'} : {}}
                            onClick={selectedCarrier => selectCarrier(selectedCarrier)}
                          />)
                    })
                  }
                </Col>
              </Row>
              <Row className={'text-center'}>
                  <div ref={packageSelectorRef} />
                 <Col>
                    {categories.map(category => {
                      return (
                        <Button
                          className={"mt-2"}
                          key={category.id}
                          size="sm"
                          onClick={() => selectCategory(category)}>
                          {category.category}
                        </Button>)
                    })
                    }
                  </Col>
              </Row>
              <PackagesBlock packages={packages} onDone={() => cleanForm()} selectedCarrier={selectedCarrier}/>
            </div>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Packages
