import React, {useEffect, useRef, useState} from "react"
import CustomerNavbar from "../../components/Navbars/CustomerNavbar"
import Shape from "../../components/Shape"
import {Button, Col, Container, Input, Label, Row} from "reactstrap"
import SimpleFooter from "../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Alert, {toast} from "../../components/Alert"
import {useAppDataStore} from "../../stores/appDataStore"
import images from "../../stores/images";


const InputBlock = observer(({numberInputRef, onChange, disabled, onSubmit, value}) => {
  const [number, setNumber] = useState(value)

  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      if (onChange) onChange(number)
      if (onSubmit) onSubmit()
    }
  }

  useEffect(() => {
    setNumber(value)
  }, [value]);

  return <Input placeholder={'Número'} value={number}
                innerRef={numberInputRef}
                onKeyUp={e => handleOnKeyUp(e)}
                disabled={disabled}
                onChange={e => setNumber(e.target.value)}
                onBlur={e => onChange ? onChange(number) : null}
  />
})

const GenericProducts = ({route}) => {

  const appDataStore = useAppDataStore()

  const [number, setNumber] = useState("")
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [busy, setBusy] = useState(false)
  const [products, setProducts] = useState(
    appDataStore
      .products
      .filter(product => product.route === route)
  )

  const numberInputRef = useRef()
  const mainRef = useRef()

  useEffect(() => {
    appDataStore
      .updateProducts()
      .then(() => {
        let filteredProducts =
          appDataStore
            .products
            .filter(product => product.route === route)
        setProducts(filteredProducts)
      })

  }, [])

  function selectProduct(product) {
    setSelectedProduct(product)
    numberInputRef.current.focus()
    numberInputRef.current.scrollTop = 0
  }

  async function exec() {

    if (!selectedProduct) return await this.toast('Debe seleccionar un producto')

    if (!number.validateMin()) return await this.toast('El número ingresado es inválido')

    let alertResult = await Alert.fire({
      title: <img style={styles.icons}
                  key={selectedProduct.id}
                  width={130}
                  src={selectedProduct.imageUrl || images.default}
                  alt={selectedProduct.name}
                  className=""/>,
      html: <>
        Numero:<b>{number}</b><br/>
        Producto: <b>{selectedProduct.name}</b><br/>

      </>,
      showCancelButton: true,
      focusConfirm: false,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Generar Venta',

    })

    if (alertResult && alertResult.value) {
      setBusy(true)
      try {
        let res = await appDataStore.post('/public-api/topup', {
          number,
          productId: selectedProduct.id
        })
        setBusy(false)
        if (res.error) return toast(res.error)


        appDataStore.updateCustomer()

        let alertResult = await Alert.fire({
          title: <img style={styles.icons}
                      key={selectedProduct.id}
                      width={130}
                      src={selectedProduct.imageUrl || images.default}
                      alt={selectedProduct.name}
                      className=""/>,
          html: res.customerResponse.replace(/\r\n/g, '<br/>') || "venta realizada con éxito",
          showCancelButton: true,
          focusConfirm: false,
          cancelButtonText: 'Cerrar',
          confirmButtonText: 'Compartir via whatsapp',
        })
        if (alertResult?.value) {
          window.open(`https://api.whatsapp.com/send/?phone=57${number}&text=${encodeURIComponent(res.customerResponse)}&type=phone_number&app_absent=0`)
        }
        setNumber('')
        setSelectedProduct(null)
      } catch (err) {
        setBusy(false)
        return toast(err.response.data.error)
      }


    }
  }

  return (
    <div>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md d-flex">
            <div className="col px-0">
              <Row>
                <Col lg="12 text-center">
                  {products.map(product => {
                    let selected = selectedProduct && selectedProduct.id === product.id
                    return (
                      <div key={product.id} style={{display: 'inline-block'}}>
                        <a onClick={() => {
                          selectProduct(product)
                        }}>
                          <img style={styles.icons}
                               width={selected ? 130 : 110}
                               src={product.imageUrl || images.default}
                               alt={product.name}
                               className={selected ? "item-selected" : "product-image"}/>
                          <p className={(selected ? "item-selected" : "") + " mb-0"}>{product.name}</p>
                          <p className={(selected ? "item-selected" : "") + " mt-0"}>{product.fixedPrice.cash()}</p>
                        </a>
                      </div>
                    )
                  })}
                </Col>
              </Row>
              <Row className="justify-content-center text-center">
                <Col lg={3} sm={8} xs={8} className="align-items-center topup-col-centered">
                  <Label>Número</Label>
                  <InputBlock numberInputRef={numberInputRef}
                              value={number}
                              onChange={number => setNumber(number)}
                              onSubmit={() => exec()}
                              disabled={busy}/>
                  <br/>
                  <Button className={'primary'} onClick={() => exec()}>Generar venta </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )

}

const styles = {
  icons: {margin: 10, borderRadius: 10}
}

export const Gambling = observer(GenericProducts)
export const DigitalPins = observer(GenericProducts)
export const DigitalAccounts = observer(GenericProducts)

export const GenericDigitalAccounts = observer(GenericProducts)

export default observer(GenericProducts)
