import React, {useEffect, useRef, useState} from "react";
import {Button, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {useAppDataStore} from "../../../../stores/appDataStore";
import Table, {Formatters} from "../../../../components/Table";
import moment from "moment";

const DrawAttachment = ({response}) => {
  const [visible, setVisible] = useState(false)
  const toggle = () => {
    setVisible(!visible)
  }
  return (
    <div>
      {visible ? <Modal
        className="modal-dialog-centered"
        isOpen={visible}
        toggle={() => toggle()}
        size="xl"
      >
        <div className="modal-body">
          <button
            aria-label="Cerrar"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggle()}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <div className={"image-attachment-large-container"}>
            <img className="image-attachment-large" onClick={toggle} src={response.attachment}/>
          </div>
        </div>
      </Modal> : null}

      <img className="image-attachment" onClick={toggle} src={response.attachment}/>
      <div className="label-attachment">{response.text}</div>
    </div>
  )
}
const ChatSupportModal = ({support, toggle, visible, onSave}) => {

  const appDataStore = useAppDataStore()
  const [text, setText] = useState('')
  const [image, setImage] = useState(null)
  const ulRef = useRef()

  useEffect(() => {
    setText('')
    if (visible) {
      if (ulRef.current) {
        setTimeout(() => {
          ulRef.current.scrollTop = 999 * 999 * 999
        }, 1500)
      }
    }
  }, [visible, support])

  const onFileSelected = (e) => {
    let [file] = e.target.files
    const reader = new FileReader()
    reader.addEventListener('load', (event) => {
      setImage(event.target.result)
    })
    reader.readAsDataURL(file)
  }

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="xl"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Estado del soporte
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <ul ref={ulRef} id="chat">
        {
          support.SupportResponses.map(response => {
            return (
              <li className={appDataStore.customer.id === response.userId ? 'me' : 'you'}>
                <div className="entete">
                  <span className="status green"></span>
                  <h2>{appDataStore.customer.id === response.userId ? appDataStore.customer.name : 'Soporte al cliente'}</h2>
                  <h3>{Formatters.datetime({value: response.createdAt})}</h3>
                </div>
                <div className="triangle"></div>
                <div className="message">
                  {response.attachment ? <DrawAttachment response={response}/> : response.text}
                </div>
              </li>
            )
          })
        }
      </ul>
      {support.status === 'pending' ?
        <Row>
          <Col lg={12}>
            <FormGroup>
              <label> Enviar respuesta</label>
            </FormGroup>
          </Col>
          <Col lg={8}>
            <FormGroup>
              <Input
                className="form-control-alternative"
                placeholder=""
                type={'textarea'}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col lg={2}>
            <Input className="custom-file-button btn-lg" accept="image/png, image/jpeg" type="file"
                   onChange={onFileSelected}/>
          </Col>
          <Col lg={2}>
            <Button color="primary" style={{display: 'inline'}} type="button" size={'lg'}
                    onClick={() => onSave(text, support.id, image)}>
              Enviar
            </Button>

          </Col>
        </Row> : null}
    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={() => toggle()}
      >
        Cerrar
      </Button>
    </div>
  </Modal>
}
export default ChatSupportModal