import React from "react"
import images from "../stores/images";

function CarrierLogo(props) {
  const {carrier, onClick, style, selected} = props
  if (!carrier) return null

  return (
    <div className={"d-inline-block ml-2"}>
    <img
      className={"carrier-logo " + (selected ? 'selected' : '')}
      style={style}
      onClick={() => onClick ? onClick(carrier) : null}
      alt={carrier.name}
      src={carrier.imageUrl  || images.default}
    />{
      /*
      <p className={selected ? "item-selected" : ""}>{carrier.name}</p>
      */
    }
    <p>{carrier.name}</p>

    </div>
  )
}

export default CarrierLogo
