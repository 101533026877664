export default {
  'netflix.4': require('assets/img/digitalAccounts/netflix.png'),
  'netflix.2': require('assets/img/digitalAccounts/netflix.png'),
  'netflix.1': require('assets/img/digitalAccounts/netflix.png'),
  'amazon.1': require('assets/img/digitalAccounts/amazon.png'),
  'disney.1': require('assets/img/digitalAccounts/disney.png'),
  'default': require('assets/img/brand/isotipo.png'),
  'aquijuego.topup': require('assets/img/gambling/aquijuego.png'),
  'yajuego.topup': require('assets/img/gambling/yajuego.png'),
  'sportium.topup': require('assets/img/gambling/sportium.png'),
  'megapuesta.topup': require('assets/img/gambling/megapuesta.png'),
  'meridian.topup': require('assets/img/gambling/meridian.png'),
  'rivalo.topup': require('assets/img/gambling/rivalo.png'),
  'rushbet.topup': require('assets/img/gambling/rushbet.png'),
  'wplay.topup': require('assets/img/gambling/wplay.png'),
  claro: require('assets/img/carriers/claro.png'),
  tigo: require('assets/img/carriers/tigo.png'),
  movistar: require('assets/img/carriers/movistar.png'),
  claro393: require('assets/img/carriers/claro393.png'),
  directv: require('assets/img/carriers/directv.png'),
  etb: require('assets/img/carriers/etb.png'),
  exito: require('assets/img/carriers/exito.png'),
  flash: require('assets/img/carriers/flash.png'),
  virgin: require('assets/img/carriers/virgin.png'),
  netflix: require('assets/img/pin/netflix.png'),
  axeso5: require('assets/img/pin/axeso5.png'),
  freefire: require('assets/img/pin/freefire.png'),
  imvu: require('assets/img/pin/imvu.png'),
  midatacredito: require('assets/img/pin/midatacredito.png'),
  noggin: require('assets/img/pin/noggin.png'),
  office365: require('assets/img/pin/office365.png'),
  playstationcol: require('assets/img/pin/playstationcol.png'),
  rixty: require('assets/img/pin/rixty.png'),
  spotify: require('assets/img/pin/spotify.png'),
  win: require('assets/img/pin/win.png'),
  xbox: require('assets/img/pin/xbox.png'),
}
