import React, {useEffect, useRef, useState} from "react"
import CustomerNavbar from "../../../../components/Navbars/CustomerNavbar"
import Shape from "../../../../components/Shape"
import {Button, Col, Container, Row} from "reactstrap"
import SimpleFooter from "../../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from '../../../../components/Table'
import {confirm, toast} from '../../../../components/Alert'
import {useAppDataStore} from "../../../../stores/appDataStore";
import EditModal from "./EditModal";

const Providers = observer(({}) => {

  const [data, setData] = useState([])
  const [editModalVisible, setEditModalVisible] = useState()
  const [selectedData, setSelectedData] = useState({})
  const mainRef = useRef()
  const appDataStore = useAppDataStore()

  useEffect(() => {
    refreshData()
    const interval = setInterval(refreshData, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const columns = [
    {
      field: 'id',
      maxWidth: 60,
      headerName: 'ID',

    },
    {
      field: 'username',
      flex: 1,
      headerName: 'Usuario'
    },
    {
      field: 'providerName',
      headerName: 'Nombre del proveedor',
      flex: 1,
    },
    {
      field: 'status.connected',
      headerName: 'Conectado',
      flex: 1,
      valueFormatter: Formatters.boolean

    },
    {
      field: 'status.balance',
      headerName: 'Saldo principal',
      flex: 1,
      valueFormatter: Formatters.cash

    },
    {
      field: 'status.balance1',
      headerName: 'Saldo comisiones',
      flex: 1,
      valueFormatter: Formatters.cash

    },
    {
      field: 'createdAt',
      headerName: 'Fecha cración',
      valueFormatter: Formatters.datetime
    },
    {
      field: '',
      flex: 1,
      maxWidth: 150,
      headerName: 'Opciones',
      cellRenderer: ({data}) => {
        return <div className="action-icons">
          <Button size={"sm"} onClick={() => toggleEditModal(data)}>
            <i className="p-0 ni ni-settings-gear-65"/>
          </Button>

          <Button size={"sm"} onClick={async () => {
            let res = await confirm(`se ${!data.enabled ? 'activará' : 'desactivará'} el proveedor ${data.providerName}:${data.username}`)
            if (res.value) {
              await appDataStore.editProvider(data.id, {enabled: !data.enabled})
              setTimeout(refreshData, 100)
              setTimeout(refreshData, 3000)
            }
          }}><i className={`p-0 ni ni-button-power ${!data.enabled ? 'text-success' : 'text-danger'}`}/></Button>

        </div>
      }
    }
  ]

  const buttons = [{
    label: 'Crear proveedor',
    icon: 'ni-circle-08',
    onClick: () => {
      setSelectedData({})
      toggleEditModal()
    }
  }]

  const rowStyle = ({data}) => {

    let style = {}
    if (!data.enabled) style = {color: 'rgb(222,216,216)', fontStyle: 'italic'}
    if (data?.status?.connected) style = {color: 'rgb(34,151,54)'}

    return style
  }

  const refreshData = async () => {
    try {
      let {providers} = await appDataStore.getProviders()
      setData(providers)
    } catch (err) {
      console.log(err)
    }
  }

  const toggleEditModal = (data) => {
    if (data) setSelectedData(data)
    setEditModalVisible(!editModalVisible)
  }

  const onSave = async (data) => {
    let res = null
    res = {}
    if (res.error) return toast(res.error)
    //await this.refreshChilds()
    toggleEditModal()
  }

  return (
    <div>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <Row>
              <Col lg={12} md={12}>
                <EditModal
                  visible={editModalVisible}
                  toggle={toggleEditModal}
                  data={selectedData}
                  onSave={onSave}
                />
                <Table
                  tableName={'Proveedores'}
                  cols={columns}
                  rows={data}
                  buttons={buttons}
                  rowStyle={rowStyle}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Providers
