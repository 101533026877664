import React, {useRef, useState} from "react"
import CustomerNavbar from "../../../components/Navbars/CustomerNavbar"
import Shape from "../../../components/Shape"
import {Button, Col, Container, Input, Row,} from "reactstrap"
import SimpleFooter from "../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from '../../../components/Table'
import ReactDatetime from "react-datetime"
import moment from "moment"
import {useAppDataStore} from "../../../stores/appDataStore"
import "react-datetime/css/react-datetime.css";
import 'moment/locale/es';

const FilterBlock = ({onGenerate}) => {

  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment())

  const generate = () => {
    onGenerate(startDate, endDate)
  }
  return (
    <Row>
      <Col lg={12}>
        <div className={'search-box'}>
          Reporte de comisiones
          <Row>
            <Col lg={6}>
              <label>Fecha inicial</label>
              <ReactDatetime
                locale={'es'}
                onChange={(date => setStartDate(date))}
                dateFormat={'YYYY-MM-DD'}
                initialValue={startDate.format('YYYY-MM-DD')}
                inputProps={{className: 'form-control form-control-sm'}}
                timeFormat={false}
                closeOnSelect={true}
              />
            </Col>
            <Col lg={6}>
              <label>Fecha Final</label>
              <ReactDatetime
                locale={'es'}
                onChange={(date => setEndDate(date))}
                dateFormat={'YYYY-MM-DD'}
                initialValue={endDate.format('YYYY-MM-DD')}
                inputProps={{className: 'form-control form-control-sm'}}
                timeFormat={false}
                closeOnSelect={true}
              />
            </Col>
          </Row>
          <p></p>
          <Button size={'sm'} onClick={generate}>Generar reporte</Button>
        </div>
      </Col>
    </Row>)
}

const Transfers = observer(() => {

  const appDataStore = useAppDataStore()

  const [data, setData] = useState([])

  const mainRef = useRef()

  const columns = [
    {
      field: 'id',
      maxWidth: 80,
      flex: 1,
      headerName: 'ID'
    },
    {
      field: 'description',
      flex: 1,
      headerName: 'Descripción'
    },
    {
      field: 'balanceBefore',
      maxWidth: 150,
      headerName: 'Saldo anterior',
      valueFormatter: Formatters.cash
    },
    {
      field: 'amount',
      headerName: 'Valor',
      maxWidth: 150,
      flex: 1,
      valueFormatter: Formatters.cash
    },
    {
      field: 'balanceAfter',
      maxWidth: 150,
      headerName: 'Saldo final',
      valueFormatter: Formatters.cash
    },
    {
      field: 'createdAt',
      maxWidth: 190,
      headerName: 'fecha',
      valueFormatter: Formatters.datetime
    }
  ]

  const generateReport = async (startDate, endDate) => {
    let res = await appDataStore.getProfits(startDate, endDate)
    if (res.accounting) setData(res.accounting)
  }

  return (
    <div>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <FilterBlock onGenerate={generateReport}/>
            <Row>
              <Col lg={12} md={12}>
                <Table cols={columns}
                       tableName={'Reporte de transferencias'}
                       rows={data}/>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Transfers
