import {Navigate} from "react-router-dom"
import {Outlet} from "react-router"
import React from "react"
import {default as appDataStore} from 'stores/appDataStore'

const Logout = () => {
  appDataStore.logout().then()
  return <Navigate to="/auth"/>
}

export default Logout
