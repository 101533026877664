import React, {useRef, useState} from "react"
import CustomerNavbar from "../../../components/Navbars/CustomerNavbar"
import Shape from "../../../components/Shape"
import {Button, Col, Container, Input, Row,} from "reactstrap"
import SimpleFooter from "../../../components/Footers/SimpleFooter"
import {observer} from "mobx-react"
import Table, {Formatters} from '../../../components/Table'
import ReactDatetime from "react-datetime"
import moment from "moment"
import {useAppDataStore} from "../../../stores/appDataStore"
import "react-datetime/css/react-datetime.css";
import 'moment/locale/es';
import ReportConsignmentModal from "./ReportConsignmentModal";


const FilterBlock = ({onGenerate}) => {

  const [startDate, setStartDate] = useState(moment().startOf('month'))
  const [endDate, setEndDate] = useState(moment())
  const [amount, setAmount] = useState()

  return (
    <Row>
      <Col lg={12}>
        <div className={'search-box'}>
          Reporte de consignaciones
          <Row>
            <Col lg={6}>
              <label>Fecha inicial</label>
              <ReactDatetime
                locale={'es'}
                onChange={(date => setStartDate(date))}
                dateFormat={'YYYY-MM-DD'}
                initialValue={startDate.format('YYYY-MM-DD')}
                inputProps={{className: 'form-control form-control-sm'}}
                timeFormat={false}
                closeOnSelect={true}
              />
            </Col>
            <Col lg={6}>
              <label>Fecha Final</label>
              <ReactDatetime
                locale={'es'}
                onChange={(date => setEndDate(date))}
                dateFormat={'YYYY-MM-DD'}
                initialValue={endDate.format('YYYY-MM-DD')}
                inputProps={{className: 'form-control form-control-sm'}}
                timeFormat={false}
                closeOnSelect={true}
              />
            </Col>
            <Col lg={6}>
              <label>Valor</label>
              <Input
                size="sm"
                type="text"
                onChange={e => setAmount(e.target.value)}/>
            </Col>
          </Row>
          <p></p>
          <Button size={'sm'} onClick={() => onGenerate(startDate, endDate, amount)}>Generar reporte</Button>
        </div>
      </Col>
    </Row>)
}

const Consignments = observer(() => {

  const appDataStore = useAppDataStore()

  const [data, setData] = useState([])
  const [reportModalVisible, setReportModalVisible] = useState(false)
  const mainRef = useRef()

  const columns = [
    {
      field: 'id',
      maxWidth: 60,
      flex: 1,
      headerName: 'ID'
    },
    {
      field: 'amount',
      flex: 1,
      headerName: 'Valor',
      valueFormatter: Formatters.cash
    },
    {
      field: 'bank',
      flex: 1,
      headerName: 'Banco'
    },
    {
      field: 'account',
      flex: 1,
      headerName: 'Cuenta'
    },
    {
      field: 'status',
      maxWidth: 150,
      headerName: 'Estado',
      valueFormatter: Formatters.status
    },
    {
      field: 'reference',
      headerName: 'Referencia',
      flex: 1,
    },
    {
      field: 'notes',
      headerName: 'Notas',
      flex: 1,
    },
    {
      field: 'createdAt',
      maxWidth: 150,
      headerName: 'fecha',
      valueFormatter: Formatters.datetime
    }
  ]

  const buttons = [{
    label: 'Reportar consignación',
    icon: 'ni-circle-08',
    onClick: () => {
      toggleReportModal()
    }
  }]

  const toggleReportModal = () => {
    setReportModalVisible(!reportModalVisible)
  }
  const generateReport = async (startDate, endDate,amount) => {
    let res = await appDataStore.getConsignmentsReport(startDate, endDate,amount)
    if (res.consignments) setData(res.consignments)
  }

  return (
    <div>
      <CustomerNavbar/>
      <main ref={mainRef}>
        <section className="section section-shaped section-lg">
          <Shape/>
          <Container className="py-lg-md">
            <FilterBlock onGenerate={generateReport}/>
            <Row>
              <Col lg={12} md={12}>
                <ReportConsignmentModal
                  visible={reportModalVisible}
                  toggle={toggleReportModal}
                  onSave={generateReport}
                />
                <Table rows={data}
                       buttons={buttons}
                       tableName={'Reporte de consignaciones'}
                       cols={columns}/>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter/>
    </div>
  )
})

export default Consignments
