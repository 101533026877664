import React, {useEffect, useState} from "react";
import {Button, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {useAppDataStore} from "../../../../stores/appDataStore";

const BalanceModal = ({user, toggle, visible, onSave}) => {

  const [amount, setAmount] = useState()
  const [action, setAction] = useState('transfer')
  const [newBalance, setNewBalance] = useState()
  const [childNewBalance, setChildNewBalance] = useState()
  const [balanceType, setBalanceType] = useState('')
  const [notes, setNotes] = useState('')

  const appDataStore = useAppDataStore()


  useEffect(() => {
    setAmount(0)
    setAction('transfer')
    setBalanceType('')
    setNotes('')
  }, [visible])

  useEffect(() => {
    updateLabels()
  }, [balanceType, amount, action])

  const updateLabels = () => {
    if (!user) return
    let amnt = action === 'transfer' ? amount : amount * -1
    let nb = Object.cash(appDataStore.customer[`balance${balanceType}`] - amnt || 0)
    let cnb = Object.cash(user[`balance${balanceType}`] + amnt || 0)
    setNewBalance(nb)
    setChildNewBalance(cnb)
  }

  if (!user) return

  return <Modal
    className="modal-dialog-centered"
    isOpen={visible}
    toggle={() => toggle()}
    size="lg"
  >
    <div className="modal-header">
      <h5 className="modal-title" id="userModal">
        Transferencia de saldo a {user.name ? user.name : ''}
      </h5>
      <button
        aria-label="Cerrar"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={() => toggle()}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
    <div className="modal-body">
      <Row>
        <Col lg={6}>
          <FormGroup>
            <label> Saldo a transferir </label>
            <Input
              className="form-control-alternative"
              defaultValue={amount}
              placeholder=""
              type={'number'}
              onChange={(e => setAmount(parseInt(e.target.value)))}
            />
          </FormGroup>


          <FormGroup>
            <label> Mi saldo disponible </label>
            <Input
              disabled={true}
              className="form-control-alternative"
              value={Object.cash(appDataStore.customer[`balance${balanceType}`])}
              placeholder=""
              type="text"
            />
          </FormGroup>

          <FormGroup>
            <label> Saldo cliente </label>
            <Input
              disabled={true}
              value={Object.cash(user[`balance${balanceType}`])}
              className="form-control-alternative"
              placeholder=""
            />
          </FormGroup>
        </Col>
        <Col lg={6}>
          <Row>
            <Col lg={6}>
              <FormGroup>
                <label> Acción </label>
                <Input
                  defaultValue={action}
                  className="form-control-alternative"
                  type="select"
                  onChange={e => setAction(e.target.value)}
                >
                  <option value="transfer"> Transferir saldo</option>
                  <option value="reverse"> Reversar saldo</option>
                </Input>
              </FormGroup>
            </Col>
            <Col lg={6}>
              <FormGroup>
                <label> Tipo de saldo </label>
                <Input
                  defaultValue={action}
                  className="form-control-alternative"
                  type="select"
                  onChange={e => setBalanceType(e.target.value)}
                >
                  <option value=""> Saldo principal</option>
                  <option value="1"> Comision</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <label> Mi nuevo saldo </label>
            <Input
              value={newBalance}
              className="form-control-alternative"
              placeholder=""
              disabled={true}
            />
          </FormGroup>
          <FormGroup>
            <label> Nuevo saldo cliente </label>
            <Input
              disabled={true}
              value={childNewBalance}
              className="form-control-alternative"
              placeholder=""
              type="text"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <label> Notas </label>
            <Input
              value={notes}
              className={"form-control-alternative"}
              type={'textarea'}
              onChange={e => setNotes(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
    <div className="modal-footer">
      <Button
        color="secondary"
        data-dismiss="modal"
        type="button"
        size={'sm'}
        onClick={() => toggle()}
      >
        Cancelar
      </Button>
      <Button color="primary" type="button" size={'sm'} onClick={() => onSave({
        user,
        amount,
        action,
        balanceType
      })}>
        Aceptar
      </Button>
    </div>
  </Modal>
}
export default BalanceModal